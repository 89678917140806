import React, { FunctionComponent, useState, useEffect, useRef, useMemo, Component } from 'react';
import cadreLeftRight from '../helpers/cadre_left_right';
import DelaiAff from '../helpers/delai_aff';
import './cadre_carre.css';

type Props = {
    delai:number;
    idd?:string
}

const tab3838CSS =
    [
        "cadreanim00 super_cadre00a cadre_e",
        "cadreanim00 super_cadre00b cadre_f",
        "cadreanim00 super_cadre00a cadre_g",
        "cadreanim00 super_cadre00b cadre_h",
    ]





const CadreCarre: FunctionComponent<Props> = ({children, delai, idd}) => {


  const tab3939CSSRandom =  tab3838CSS[Math.floor(Math.random() * tab3838CSS.length)]

    const ref = useRef<HTMLDivElement>(null)

    const useOnScreen = (ref: RefObject<HTMLElement>) => {

        const [isIntersecting, setIntersecting] = useState(false)

        const observer = useMemo(() => new IntersectionObserver(
          ([entry]) => setIntersecting(entry.isIntersecting)
        ), [ref])


        useEffect(() => {
          observer.observe(ref.current)
          return () => observer.disconnect()
        }, [])

        return isIntersecting
      }

  const isVisible = useOnScreen(ref)


  return(
       <>
    {/*<div  onContextMenu={(e)=>  {e.preventDefault(); return false;}} className={tab3939CSSRandom}   style={{display:DelaiAff(delai)}} ></div>*/}
    <div ref={ref} onContextMenu={(e)=>  {e.preventDefault(); return false;}} className={tab3939CSSRandom}>
    <div className='idAncre00'  id={idd}></div>
        {children}
        </div>
      </>
  )

  }

  export default CadreCarre;
