import React, { FunctionComponent, useState } from 'react';
import './module_paysage_ocean.css';
import carre000 from "../assets/img/img_space/FondVide800x720.gif"
type Props = {
};

const ModulePaysageOcean: FunctionComponent<Props> = () => {

  const formatDatePO = (h: number, m: number) => {
    return new Intl.DateTimeFormat('fr-FR', { hour: '2-digit', minute: '2-digit' }).format(new Date().setHours(h, m))
  }

  const tabEltsPO0 = [
    {
      id: 0, CCSPersoPO: "cadrePO38 cadrePO38Perso00", CCS3838Fond: "cadrePO38 cadrePO38Fond00", Txt3838h: 0,
      CCSBtPOBt1: "CCSBtPO CCSBtPOBt1", CCSBtPOBt2: "CCSBtPO CCSBtPOBt2", CCSBtPOBt3: "CCSBtPO CCSBtPOBt3",
      CCSBtPOBt4: "CCSBtPO CCSBtPOBt4", CCSBtPOBt5: "CCSBtPO CCSBtPOBt5", CCSBtPOBt6: "CCSBtPO_active CCSBtPOBt6"
    },
    {
      id: 1, CCSPersoPO: "cadrePO38 cadrePO38Perso04", CCS3838Fond: "cadrePO38 cadrePO38Fond04", Txt3838h: 4,
      CCSBtPOBt1: "CCSBtPO_active CCSBtPOBt1", CCSBtPOBt2: "CCSBtPO CCSBtPOBt2", CCSBtPOBt3: "CCSBtPO CCSBtPOBt3",
      CCSBtPOBt4: "CCSBtPO CCSBtPOBt4", CCSBtPOBt5: "CCSBtPO CCSBtPOBt5", CCSBtPOBt6: "CCSBtPO CCSBtPOBt6"
    },
    {
      id: 2, CCSPersoPO: "cadrePO38 cadrePO38Perso08", CCS3838Fond: "cadrePO38 cadrePO38Fond08", Txt3838h: 8,
      CCSBtPOBt1: "CCSBtPO CCSBtPOBt1", CCSBtPOBt2: "CCSBtPO_active CCSBtPOBt2", CCSBtPOBt3: "CCSBtPO CCSBtPOBt3",
      CCSBtPOBt4: "CCSBtPO CCSBtPOBt4", CCSBtPOBt5: "CCSBtPO CCSBtPOBt5", CCSBtPOBt6: "CCSBtPO CCSBtPOBt6"
    },
    {
      id: 3, CCSPersoPO: "cadrePO38 cadrePO38Perso12", CCS3838Fond: "cadrePO38 cadrePO38Fond12", Txt3838h: 12,
      CCSBtPOBt1: "CCSBtPO CCSBtPOBt1", CCSBtPOBt2: "CCSBtPO CCSBtPOBt2", CCSBtPOBt3: "CCSBtPO_active CCSBtPOBt3",
      CCSBtPOBt4: "CCSBtPO CCSBtPOBt4", CCSBtPOBt5: "CCSBtPO CCSBtPOBt5", CCSBtPOBt6: "CCSBtPO CCSBtPOBt6"
    },
    {
      id: 4, CCSPersoPO: "cadrePO38 cadrePO38Perso16", CCS3838Fond: "cadrePO38 cadrePO38Fond16", Txt3838h: 16,
      CCSBtPOBt1: "CCSBtPO CCSBtPOBt1", CCSBtPOBt2: "CCSBtPO CCSBtPOBt2", CCSBtPOBt3: "CCSBtPO CCSBtPOBt3",
      CCSBtPOBt4: "CCSBtPO_active  CCSBtPOBt4", CCSBtPOBt5: "CCSBtPO CCSBtPOBt5", CCSBtPOBt6: "CCSBtPO CCSBtPOBt6"
    },
    {
      id: 5, CCSPersoPO: "cadrePO38 cadrePO38Perso20", CCS3838Fond: "cadrePO38 cadrePO38Fond20", Txt3838h: 20,
      CCSBtPOBt1: "CCSBtPO CCSBtPOBt1", CCSBtPOBt2: "CCSBtPO CCSBtPOBt2", CCSBtPOBt3: "CCSBtPO CCSBtPOBt3",
      CCSBtPOBt4: "CCSBtPO CCSBtPOBt4", CCSBtPOBt5: "CCSBtPO_active  CCSBtPOBt5", CCSBtPOBt6: "CCSBtPO CCSBtPOBt6"
    }]


const [CSSTagPO,setCSSTagPO ] = useState<boolean>(false);
  const [CSSTxtHPOa, setCSSTxtHPOa] = useState<string>("block");
  const [CSSTxtHPOb, setCSSTxtHPOb] = useState<string>("none");

  const [TabPORandomId, SetTabPORandomId] = useState<number>(Math.floor(Math.random() * 6))

  let TxtHorlogePOId = (tabEltsPO0.find(({ id }) => id === TabPORandomId).Txt3838h);

  const [TxtHorlogePO, setTxtHorlogePO] = useState<Date>(formatDatePO(TxtHorlogePOId, 0));

  const decptPOh3 = (idh: number, m: number, s: string, idd: number) => {
    setCSSTagPO(true);
    setCSSTxtHPOa("none");
    setCSSTxtHPOb("block");
    const ecartPO = TxtHorlogePOId - idh;
    const coefPO = (ecartPO > 0)?(ecartPO):((24+ecartPO));
    let tempoPO = 1*coefPO;

    if (formatDatePO(TxtHorlogePOId, m) === s) {
      setCSSTagPO(false);
      SetTabPORandomId(idd);
      TxtHorlogePOId = idh;
      setCSSTxtHPOa("block");
      setCSSTxtHPOb("none");
    }
    else {
      return (
        setTimeout(() => {
        decptPOh3(idh, m + 10, s, idd);
        setTxtHorlogePO(formatDatePO(TxtHorlogePOId, m));
      },tempoPO))
    }
  }

  const overPOImg1 = () => {
    decptPOh3(4, 0, "04:10", 1);
  }

  const overPOImg2 = () => {
    decptPOh3(8, 0, "08:10", 2);
  }

  const overPOImg3 = () => {
    decptPOh3(12, 0, "12:10", 3);
  }

  const overPOImg4 = () => {
    decptPOh3(16, 0, "16:10", 4);
  }

  const overPOImg5 = () => {
    decptPOh3(20, 0, "20:10", 5);
  }

  const overPOImg6 = () => {
    decptPOh3(0, 0, "00:10", 0);
  }


return <>
      <div className="cadrePO" style={{ width: "100%" }}>
        <div className="cadrePO_pl"></div>
        {/*<img className="imgFondPO" src="../assets/img/img_vide/Carre00.gif" />*/}
        <img className="imgFondPO" src={carre000} />
        <div className={tabEltsPO0.find(({ id }) => id === TabPORandomId).CCS3838Fond}>
        </div>
        <div className={tabEltsPO0.find(({ id }) => id === TabPORandomId).CCSPersoPO}>
        </div>
        <div className="horlogePO">
          <h2><span className="winner000 winnerPO">Heure&nbsp;:&nbsp;</span></h2>
          <h1 className='h2_txtPO light' ><span style={{ display: CSSTxtHPOa }}>&nbsp;
            {formatDatePO(tabEltsPO0.find(({ id }) => id === TabPORandomId).Txt3838h, 0)}</span>
            <span style={{ display: CSSTxtHPOb }}>&nbsp;{TxtHorlogePO}</span></h1>
        </div>
        <div className="barBtPO">
          <div className={(CSSTagPO)?"CCSBtPO_active CCSBtPOBt1":(tabEltsPO0.find(({ id }) => id === TabPORandomId).CCSBtPOBt1)} onClick={overPOImg1}>
          </div>
          <div className={(CSSTagPO)?"CCSBtPO_active CCSBtPOBt2":(tabEltsPO0.find(({ id }) => id === TabPORandomId).CCSBtPOBt2)} onClick={overPOImg2}>
          </div>
          <div className={(CSSTagPO)?"CCSBtPO_active CCSBtPOBt3":(tabEltsPO0.find(({ id }) => id === TabPORandomId).CCSBtPOBt3)} onClick={overPOImg3}>
          </div>
          <div className={(CSSTagPO)?"CCSBtPO_active CCSBtPOBt4":(tabEltsPO0.find(({ id }) => id === TabPORandomId).CCSBtPOBt4)} onClick={overPOImg4}>
          </div>
          <div className={(CSSTagPO)?"CCSBtPO_active CCSBtPOBt5":(tabEltsPO0.find(({ id }) => id === TabPORandomId).CCSBtPOBt5)} onClick={overPOImg5}>
          </div>
          <div className={(CSSTagPO)?"CCSBtPO_active CCSBtPOBt6":(tabEltsPO0.find(({ id }) => id === TabPORandomId).CCSBtPOBt6)} onClick={overPOImg6}>
          </div>
        </div>
      </div>
  </>
}

export default ModulePaysageOcean;