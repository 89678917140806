import React, { FunctionComponent, useState, useEffect } from 'react';
import logo from "../assets/img/img_t/LogoMine2Sly.webp"
import './module_header.css';

type Props = {
};

const ModuleHeader: FunctionComponent<Props> = () => {

  let visi:boolean=true;

  const [CSSHeader, setCSSHeader] = useState<string>("heade000");
  const rondW = ()=> {
    visi = false;
    setCSSHeader("heade000 hide");
  }
  return <>
  <header className={CSSHeader}>
  <div className="inner">
    <div className="content" >
      <h1>Mine 2 Sly</h1>
      <h2><img src={logo} className="image fit2" style={{width:"30%"}} /></h2>
      <a  className="reeponse000 buttonH000 big alt bouton_display" onClick={rondW}><span>Bienvenue</span></a>
      <h2><b>L.A. Nantes <br/>Illustrations & Griboullis</b></h2>
      <h2>Print & Web</h2>
    </div>
    <a className="buttonH000 hidden" data-active="active"><span>Bienvenue</span></a>
  </div>
</header>
</>
}

export default ModuleHeader;
