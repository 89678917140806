import React, { FunctionComponent, useState } from 'react';
import './module_rois_mages.css';

import RoisMagesRoiBaltazar_01 from "../assets/img/img_rm/200_RoisMagesRoiBaltazar_01.webp";
import RoisMagesPortailBaltazar_01 from "../assets/img/img_rm/200_RoisMagesPortailBaltazar_01.webp";
import RoisMagesRoiKasper_01 from "../assets/img/img_rm/200_RoisMagesRoiKasper_01.webp";
import RoisMagesPortailKasper_01 from "../assets/img/img_rm/200_RoisMagesPortailKasper_01.webp";
import RoisMagesRoiMelkior_01 from "../assets/img/img_rm/200_RoisMagesRoiMelkior_01.webp";
import RoisMagesPortailMelkior_01 from "../assets/img/img_rm/200_RoisMagesPortailMelkior_01.webp";
import Carre00 from "../assets/img/img_space/FondVide800x720.gif"
type Props = {
  };
  
const ModuleRoiMages: FunctionComponent<Props> = () => {

    const tabEltsRMa = [
        { id: 0, cadreRM17: "cadreRM17aa", btRM17b1: "btRM17B_active", btRM17b2: "btRM17K", btRM17b3: "btRM17M" },
        { id: 1, cadreRM17: "cadreRM17bb", btRM17b1: "btRM17B", btRM17b2: "btRM17K_active", btRM17b3: "btRM17M" },
        { id: 2, cadreRM17: "cadreRM17cc", btRM17b1: "btRM17B", btRM17b2: "btRM17K", btRM17b3: "btRM17M_active" }
    ]

    const IdRandom = Math.floor(Math.random() * 3);

    const [CSScadreRM17z, setCSScadreRM17z] = useState(tabEltsRMa.find(({ id }) => id === IdRandom).cadreRM17)

    const [CSSImgRMF, setCSSImgRMF] = useState("img17RM_first img17RM7_first");

    const [CSSImgRML, setCSSImgRML] = useState("img17RM_last img17RM7_last");

    const [CSSBtRM1, setCSSBtRM1] = useState(tabEltsRMa.find(({ id }) => id === IdRandom).btRM17b1);

    const [CSSBtRM2, setCSSBtRM2] = useState(tabEltsRMa.find(({ id }) => id === IdRandom).btRM17b2);

    const [CSSBtRM3, setCSSBtRM3] = useState(tabEltsRMa.find(({ id }) => id === IdRandom).btRM17b3);

    const actRMM = () => {
        setCSSImgRMF("img17RM_first");
        setCSSImgRML("img17RM_last");
        setCSSBtRM1("btRM17B_active");
        setCSSBtRM2("btRM17K");
        setCSSBtRM3("btRM17M");
        setTimeout(() => {
            setCSScadreRM17z("cadreRM17aa");
        }, 1000)
        setTimeout(() => {
            setCSSImgRMF("img17RM_first img17RM7_first")
            setCSSImgRML("img17RM_last img17RM7_last")
        }, 5000)
    }

    const actRMB = () => {
        setCSSImgRMF("img17RM_first")
        setCSSImgRML("img17RM_last")
        setCSSBtRM1("btRM17B");
        setCSSBtRM2("btRM17K_active");
        setCSSBtRM3("btRM17M");
        setTimeout(() => {
            setCSScadreRM17z("cadreRM17bb")
        }, 1000)
        setTimeout(() => {
            setCSSImgRMF("img17RM_first img17RM7_first")
            setCSSImgRML("img17RM_last img17RM7_last")
        }, 5000)
    }

    const actRMK = () => {
        setCSSImgRMF("img17RM_first")
        setCSSImgRML("img17RM_last")
        setCSSBtRM1("btRM17B");
        setCSSBtRM2("btRM17M");
        setCSSBtRM3("btRM17K_active");
        setTimeout(() => {
            setCSScadreRM17z("cadreRM17cc")
        }, 1000)
        setTimeout(() => {
            setCSSImgRMF("img17RM_first img17RM7_first")
            setCSSImgRML("img17RM_last img17RM7_last")
        }, 5000)
    }

    return <>
        <div className="cadreRM" style={{ width: "100%" }}>
                <div className="cadreRM_pl"></div>
                <img className="imgFondRM" src={Carre00} />
                <div className={CSScadreRM17z}>
                    <div className="cadreRM17a">
                        <img className="img17RM imgFondRM" src={RoisMagesRoiBaltazar_01} />
                        <div className="EtoileRM"><img className="imgFondRM" src={Carre00} />
                        <div className="EtoileRM1"></div>
                        <div className="EtoileRM2"></div>
                        <div className="EtoileRM3"></div>
                        <div className="EtoileRM4"></div>
                        <div className="EtoileRM5"></div>
                        <div className="EtoileRM6"></div>
                        <div className="EtoileRM7"></div>
                        <div className="EtoileRM8"></div>
                        <div className="EtoileRM9"></div>
                        </div>
                        <img className={CSSImgRMF} src={RoisMagesPortailBaltazar_01} />
                        <img className={CSSImgRML} src={RoisMagesPortailBaltazar_01} />
                    </div>
                    <div className="cadreRM17b">
                        <img className="img17RM imgFondRM" src={RoisMagesRoiKasper_01} />
                        <div className="EtoileRM"><img className="imgFondRM" src={Carre00} />
                        <div className="EtoileRM1"></div>
                        <div className="EtoileRM2"></div>
                        <div className="EtoileRM3"></div>
                        <div className="EtoileRM4"></div>
                        <div className="EtoileRM5"></div>
                        <div className="EtoileRM6"></div>
                        <div className="EtoileRM7"></div>
                        <div className="EtoileRM8"></div>
                        <div className="EtoileRM9"></div>
                        </div>
                        <img className={CSSImgRMF} src={RoisMagesPortailKasper_01} />
                        <img className={CSSImgRML} src={RoisMagesPortailKasper_01} />
                    </div>
                    <div className="cadreRM17c">
                        <img className="img17RM imgFondRM" src={RoisMagesRoiMelkior_01} />
                        <div className="EtoileRM"><img className="imgFondRM" src={Carre00} />
                        <div className="EtoileRM1"></div>
                        <div className="EtoileRM2"></div>
                        <div className="EtoileRM3"></div>
                        <div className="EtoileRM4"></div>
                        <div className="EtoileRM5"></div>
                        <div className="EtoileRM6"></div>
                        <div className="EtoileRM7"></div>
                        <div className="EtoileRM8"></div>
                        <div className="EtoileRM9"></div>
                        </div>
                        <img className={CSSImgRMF} src={RoisMagesPortailMelkior_01} />
                        <img className={CSSImgRML} src={RoisMagesPortailMelkior_01} />
                    </div>
                </div>
                <div className="barBtRM">
                    <div className={CSSBtRM1} onClick={actRMM}>
                    </div>
                    <div className={CSSBtRM2} onClick={actRMB}>
                    </div>
                    <div className={CSSBtRM3} onClick={actRMK}>
                    </div>
                </div>
            </div>
    </>
}

export default ModuleRoiMages;

