import React, { FunctionComponent, useState, useRef, useEffect, useContext, createContext, Ref } from 'react';
import ReactDOM from 'react-dom';
import Carre00 from "../assets/img/img_space/FondVide800x720.gif";
import lev01 from "../assets/img/img_lev/600_Mino_01.webp";
import lev02 from "../assets/img/img_lev/600_Mino_02.webp";
import lev03 from "../assets/img/img_lev/600_Mino_03.webp";
import lev04 from "../assets/img/img_lev/600_Mino_04.webp";
import lev05 from "../assets/img/img_lev/600_Mino_05.webp";
import lev06 from "../assets/img/img_lev/600_Mino_06.webp";
import lev07 from "../assets/img/img_lev/600_Mino_07.webp";
import lev08 from "../assets/img/img_lev/600_Mino_08.webp";
import lev09 from "../assets/img/img_lev/600_Mino_09.webp";
import lev10 from "../assets/img/img_lev/600_Mino_10.webp";
import lev11 from "../assets/img/img_lev/600_Mino_11.webp";
import lev12 from "../assets/img/img_lev/600_Mino_12.webp";

import './module_sans_issue.css';



type Props = {
};

const ModuleSansIssue: FunctionComponent<Props> = () => {

   const tab42Elts00 = [
      { id: 0, pos: "a01", scr: lev01, ok: false, CCSPiece42L: "0%", CCSPiece42T: "0%", IdPiece42: "ref01", ZIdx42: "10",ombr42:"0 0 0 0" },
      { id: 2, pos: "a02", scr: lev02, ok: false, CCSPiece42L: "5%", CCSPiece42T: "0%", IdPiece42: "ref02", ZIdx42: "10",ombr42:"0 0 0 0" },
      { id: 3, pos: "a03", scr: lev03, ok: false, CCSPiece42L: "10%", CCSPiece42T: "0%", IdPiece42: "ref03", ZIdx42: "10",ombr42:"0 0 0 0" },
      { id: 4, pos: "b01", scr: lev04, ok: false, CCSPiece42L: "15%", CCSPiece42T: "0%", IdPiece42: "ref04", ZIdx42: "10",ombr42:"0 0 0 0" },
      { id: 5, pos: "b02", scr: lev05, ok: false, CCSPiece42L: "20%", CCSPiece42T: "0%", IdPiece42: "ref05", ZIdx42: "10",ombr42:"0 0 0 0" },
      { id: 6, pos: "b03", scr: lev06, ok: false, CCSPiece42L: "25%", CCSPiece42T: "0%", IdPiece42: "ref06", ZIdx42: "10",ombr42:"0 0 0 0" },
      { id: 7, pos: "c01", scr: lev07, ok: false, CCSPiece42L: "30%", CCSPiece42T: "0%", IdPiece42: "ref07", ZIdx42: "10",ombr42:"0 0 0 0" },
      { id: 8, pos: "c02", scr: lev08, ok: false, CCSPiece42L: "35%", CCSPiece42T: "0%", IdPiece42: "ref08", ZIdx42: "10",ombr42:"0 0 0 0" },
      { id: 9, pos: "c03", scr: lev09, ok: false, CCSPiece42L: "40%", CCSPiece42T: "0%", IdPiece42: "ref09", ZIdx42: "10",ombr42:"0 0 0 0" },
      { id: 10, pos: "d01", scr: lev10, ok: false, CCSPiece42L: "45%", CCSPiece42T: "0%", IdPiece42: "ref10", ZIdx42: "10",ombr42:"0 0 0 0" },
      { id: 11, pos: "d02", scr: lev11, ok: false, CCSPiece42L: "50%", CCSPiece42T: "0%", IdPiece42: "ref11", ZIdx42: "10",ombr42:"0 0 0 0" },
      { id: 12, pos: "d03", scr: lev12, ok: false, CCSPiece42L: "55%", CCSPiece42T: "0%", IdPiece42: "ref12", ZIdx42: "10" ,ombr42:"0 0 0 0"}
   ]
   
   const tab42Start00 = [
      {CCSPiece42L: "1%", CCSPiece42T: "1%"},
      {CCSPiece42L: "3%", CCSPiece42T: "9%"},
      {CCSPiece42L: "5%", CCSPiece42T: "15%"},
      {CCSPiece42L: "9%", CCSPiece42T: "26%"},
      {CCSPiece42L: "5%", CCSPiece42T: "31%"},
      {CCSPiece42L: "4%", CCSPiece42T: "36%"},
      {CCSPiece42L: "7%", CCSPiece42T: "41%"},
      {CCSPiece42L: "1%", CCSPiece42T: "49%"},
      {CCSPiece42L: "1%", CCSPiece42T: "58%"},
      {CCSPiece42L: "9%", CCSPiece42T: "61%"},
      {CCSPiece42L: "1%", CCSPiece42T: "66%"},
      {CCSPiece42L: "3%", CCSPiece42T: "74%"}
   ]
   
   const tab42Case00 = [
      { id: "a01", CCSCase42L: 33, CCSCase42T: 1 },
      { id: "a02", CCSCase42L: 55, CCSCase42T: 1 },
      { id: "a03", CCSCase42L: 77, CCSCase42T: 1 },
      { id: "b01", CCSCase42L: 33, CCSCase42T: 25.5},
      { id: "b02", CCSCase42L: 55, CCSCase42T: 25.5},
      { id: "b03", CCSCase42L: 77, CCSCase42T: 25.5},
      { id: "c01", CCSCase42L: 33, CCSCase42T: 50},
      { id: "c02", CCSCase42L: 55, CCSCase42T: 50},
      { id: "c03", CCSCase42L: 77, CCSCase42T: 50},
      { id: "d01", CCSCase42L: 33, CCSCase42T: 74.5},
      { id: "d02", CCSCase42L: 55, CCSCase42T: 74.5},
      { id: "d03", CCSCase42L: 77, CCSCase42T: 74.5}
   ]

   const tabElts42A = () => {
      const tabElts421 = tab42Elts00.map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
        let tabElts422 = []
        for (let i = 0; i < tabElts421.length; i++) {
         tabElts422[i] = tabElts421[i]
         tabElts422[i].CCSPiece42L = tab42Start00[i].CCSPiece42L
         tabElts422[i].CCSPiece42T = tab42Start00[i].CCSPiece42T
       }
   return tabElts422
    }

   const [tab42Elts, setTab42Elts] = useState(tabElts42A())
   const [zIndex42, setzIndex42] = useState<number>(50);
   const [tag42, setTag42] = useState<boolean>(false)
   const [idPiece42, setIdPiece42] = useState<string>("")
   const [CSSBt42,setCSSBt42] = useState<string>("none")
   const [CSSFd42,setCSSFd42] = useState<string>("block")

   /*
    let tab42EltsB = tab42Elts;
   for (let i = 0; i < tab42EltsB.length; i++) {
      for (let j = 1; j < tab42EltsB.length; j++) {
         if((tab42EltsB[i].CCSPiece42T == tab42EltsB[j].CCSPiece42T) && (tab42EltsB[i].CCSPiece42L == tab42EltsB[j].CCSPiece42L)){
            tab42EltsB[j].CCSPiece42T = ("1%")
            tab42EltsB[j].CCSPiece42L = ("1%")
            setTab42Elts(tab42EltsB)
         }
      }
    }
      box-shadow: 0 3em 2em -1em #000;
      */

   const omu42 = (r: string, e: React.ChangeEvent<HTMLInputElement>) => {
   }

   const omd42 = (r: string, e: React.ChangeEvent<HTMLInputElement>) => {
      setTag42(!tag42)
     // if(tag42 == true){
        // setTab42Elts(tab42Elts.map(tab => tab.IdPiece42 == r ?{...tab,ombr42:"0 3em 2em -1em #000"}:tab))
    //  }else{
    //     setTab42Elts(tab42Elts.map(tab => tab.IdPiece42 == r ?{...tab,ombr42:"0 0 0 0"}:tab))
    //  }
      setIdPiece42(r)
      setzIndex42(zIndex42 + 10)
      let etTag42a = 0
      if (tag42 == false) {
      setTab42Elts(tab42Elts.map(tab => tab.IdPiece42 == r ?{...tab,ZIdx42:zIndex42,ombr42:"1em 1.25em 4em 1.5em #000"}:tab))
      }
      else{
         setTab42Elts(tab42Elts.map(tab => tab.ombr42 == "1em 1.25em 4em 1.5em #000" ?{...tab,ZIdx42:zIndex42,ombr42:"0 0 0 0"}:tab))
      }
      tab42Elts.map(tab => tab.ok == true ? etTag42a = etTag42a+1 : null);
      console.log(etTag42a)
      if(etTag42a == 12){
         setCSSFd42("none");
         setTimeout(() => {setCSSBt42("block")},4000)
      }
      console.log(tab42Elts)
   }

   const omm42 = (e: React.ChangeEvent<HTMLInputElement>) => {
      const elt42 = document.getElementById("cadrePuz42");
      const elt42lg = elt42.getBoundingClientRect().width;
      const elt42ht = elt42.getBoundingClientRect().height;
      const elt42X = elt42.getBoundingClientRect().x;
      const elt42Y = elt42.getBoundingClientRect().y;
      const Pct42lg = elt42lg / 100;
      const Pct42ht = elt42ht / 100;
      let eMouse42L = ((((e.clientX) - (elt42X)) / Pct42lg) - 10);
      let eMouse42T = ((((e.clientY) - (elt42Y)) / Pct42ht) - 11.11);
      let eTest42 = "";
      if (tag42 == true) {
         tab42Case00.map(tab => (((eMouse42L - 5) < tab.CCSCase42L) && (tab.CCSCase42L < (eMouse42L + 5)) && ((eMouse42T - 5) < tab.CCSCase42T) && (tab.CCSCase42T < (eMouse42T + 5))) ? (eMouse42L = (tab.CCSCase42L), eMouse42T = (tab.CCSCase42T), eTest42 = (tab.id)) : null);
         setTab42Elts(tab42Elts.map(
            tab => tab.IdPiece42 == idPiece42 ?
               {
                  ...tab,
                  CCSPiece42L: (`${(eMouse42L > 0 ? (eMouse42L < 100 ? eMouse42L : 100) : 0)}%`),
                  CCSPiece42T: (`${(eMouse42T > 0 ? (eMouse42T < 100 ? eMouse42T : 100) : 0)}%`),
                  ok: (tab.pos == eTest42) ? true : false
               } : tab))
      }
   }

   const rejouer42 = () => {
      setCSSBt42("none")
      setCSSFd42("block")
      setTab42Elts(tabElts42A())
   }

   return <>
      <div className="cadre42" style={{ width: "100%" }}>
         <div className='idAncre00'></div>
         <div className="cadre42_pl"></div>
         <img className="imgFond42" src={Carre00} />
         <div className="cadre427">
            <div className="canvas42" id="cadrePuz42" onMouseMove={e => omm42(e)}>
            <div className="canvas422"  style={{ display: CSSFd42 }}> </div>
               <div className="canvas4222"> </div>

               {tab42Elts.map((elt) => (
                  <div className="piece42" style={{ left: elt.CCSPiece42L, top: elt.CCSPiece42T, boxShadow: elt.ombr42, zIndex: elt.ZIdx42 }} id={elt.IdPiece42}
                     onMouseDown={e => omd42(elt.IdPiece42, e)} onMouseMove={e => omm42(elt.IdPiece42, e)} onClick={e => omu42(elt.IdPiece42, e)}>
                     <img className="imgPiece42" src={elt.scr} />
                  </div>
               ))}

            </div>
         </div>
         <div className="cadre42_msg" style={{ display: CSSBt42 }}>
            <div className="cadre427_msg">
               <div className="cadre4277_msg">
                  <h2 className="winner000"><span>Bravo !</span></h2>
                  <button className="reponse000" onClick={rejouer42}>Rejouer?</button>
               </div>
            </div>
         </div>
      </div>
   </>
}

export default ModuleSansIssue;