import DessinS from './dessin-s';

export const DESSINS_S: DessinS[] = [

    {
        imgScr: "/assets/img/img_art_box3/100_AnaAnabelleEtAnastasia_01.webp",
        imgTxt: "Ana, Anabelle et Anastasia",
        imgGrp: "listImgFlip01"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LAutreCoteDuMirroir_02.webp",
        imgTxt: "L'autre coté du mirroir",
        imgGrp: "listImgFlip01"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_OdysseeChantXII_01.webp",
        imgTxt: "Odyssee chant XII",
        imgGrp: "listImgFlip01"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_SongeDUneNuitDEte_01.webp",
        imgTxt: "Songe d'unr nuit d'été",
        imgGrp: "listImgFlip01"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_HorreurMalheur_01.webp",
        imgTxt: "Horreur! Malheur!",
        imgGrp: "listImgFlip02"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LaBouilleIncroyable_01.webp",
        imgTxt: "La bouille incroyable",
        imgGrp: "listImgFlip02"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LesPilesDeBoites_01.webp",
        imgTxt: "Les piles de boites",
        imgGrp: "listImgFlip02"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_MilleEtUneMains_01.webp",
        imgTxt: "Milles et une mains",
        imgGrp: "listImgFlip02"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_JupiterCEstLaLiberte_01.webp",
        imgTxt: "Jupiter, c'est la liberté",
        imgGrp: "listImgFlip03"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_JupiterCEstLAventure_01.webp",
        imgTxt: "Jupiter, c'est l'aventure",
        imgGrp: "listImgFlip03"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_ChapeauFleuri_01.webp",
        imgTxt: "Chapeau fleuri",
        imgGrp: "listImgFlip04"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LeBienEtLeMal_01.webp",
        imgTxt: "Le bien et le mal",
        imgGrp: "listImgFlip04"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LesMotsDeJeanneDArc_02.webp",
        imgTxt: "Les mots de Jeanne d'Arc",
        imgGrp: "listImgFlip04"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LeCheminDeCampagne_01.webp",
        imgTxt: "Le chemin de campagne",
        imgGrp: "listImgFlip05"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LeCheminDesSouterrains_01.webp",
        imgTxt: "Le chemin des souterrains",
        imgGrp: "listImgFlip05"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LeCheminDuTemple_01.webp",
        imgTxt: "Le chemin du temple",
        imgGrp: "listImgFlip05"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_Diabolik_01.webp",
        imgTxt: "Les cornes du demon",
        imgGrp: "listImgFlip06"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LeCouteauOuLaHache_03.webp",
        imgTxt: "Le couteau ou la hache",
        imgGrp: "listImgFlip06"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_BaignadeInterdite_01.webp",
        imgTxt: "Baignade interdite",
        imgGrp: "listImgFlip07"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_DerriereLeMur_02.webp",
        imgTxt: "Derrière le mur",
        imgGrp: "listImgFlip07"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LaVieReveeDesOurs_03.webp",
        imgTxt: "La vie rêvée des ours",
        imgGrp: "listImgFlip07"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LeBouddhaDeKamakura_01.webp",
        imgTxt: "Le bouddha de Kamakura",
        imgGrp: "listImgFlip07"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LeMasqueDeKumo_01.webp",
        imgTxt: "Le masque de Kumo",
        imgGrp: "listImgFlip08"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LeMasqueDeSaruEtLeMasqueDeNeko_01.webp",
        imgTxt: "Les masques de Saru et de Neko",
        imgGrp: "listImgFlip08"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LeMasqueDeShiShi_01.webp",
        imgTxt: "Le masque de Shi Shi",
        imgGrp: "listImgFlip08"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_EchecEtMatLeCoupMortel_01.webp",
        imgTxt: "Echec et mat: le coup mortel",
        imgGrp: "listImgFlip09"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LaSalsaVerte_01.webp",
        imgTxt: "La salsa verte",
        imgGrp: "listImgFlip09"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LeCapitaineNemo_01.webp",
        imgTxt: "Le capitaine Nemo",
        imgGrp: "listImgFlip09"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LeJardinDeBouddha_01.webp",
        imgTxt: "Le jardin de Bouddha",
        imgGrp: "listImgFlip09"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_ArtParkDeSapporo_01.webp",
        imgTxt: "ArtPark de Sapporo",
        imgGrp: "listImgFlip10"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_AttentionOursNippon_01.webp",
        imgTxt: "Attention! Présence d'ours.",
        imgGrp: "listImgFlip10"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_PleineLuneDeNagano_02.webp",
        imgTxt: "La pleine lune de Nagano",
        imgGrp: "listImgFlip10"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_PortraitsNippons_01.webp",
        imgTxt: "Portraits Nippons",
        imgGrp: "listImgFlip10"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_RencontreEntreTokyoEtKyoto_01.webp",
        imgTxt: "Rencontre entre Tokyo et Kyoto",
        imgGrp: "listImgFlip10"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LesAphorismesDeFNietzsche_01.webp",
        imgTxt: "Les aphorismes de FNietzsche",
        imgGrp: "listImgFlip11"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LesMotsDeSaintValentin_01.webp",
        imgTxt: "Les mots de la Saint Valentin",
        imgGrp: "listImgFlip11"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_Bingo_01.webp",
        imgTxt: "Bingo! ",
        imgGrp: "listImgFlip12"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LaFeePrisonniere_02.webp",
        imgTxt: "La fée prisonnière",
        imgGrp: "listImgFlip12"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LEspritPremier_01.webp",
        imgTxt: "L'esprit premier",
        imgGrp: "listImgFlip12"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_MissSpirale_04.webp",
        imgTxt: "L'étrange Miss Spirale",
        imgGrp: "listImgFlip12"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_AperoPiscine_01.webp",
        imgTxt: "L'apéro piscine",
        imgGrp: "listImgFlip13"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_BallonsMultiColors_03.webp",
        imgTxt: "Les ballons multicolores",
        imgGrp: "listImgFlip13"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LeJollyRoger_02.webp",
        imgTxt: "Le Jolly Roger",
        imgGrp: "listImgFlip13"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_NewYorkNewYork_01.webp",
        imgTxt: "New York! New York!",
        imgGrp: "listImgFlip13"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_FinesRondellesNippones_04.webp",
        imgTxt: "Les fines rondelles",
        imgGrp: "listImgFlip14"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LesVegas_01.webp",
        imgTxt: "Las Vegas",
        imgGrp: "listImgFlip14"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LaReineDUnJour_01.webp",
        imgTxt: "La reine d'un jour",
        imgGrp: "listImgFlip15"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LesAgentsDuMI6_01.webp",
        imgTxt: "Les agents du MI6",
        imgGrp: "listImgFlip15"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LesEnfantsTerribles_01.webp",
        imgTxt: "Les enfants terribles",
        imgGrp: "listImgFlip15"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LEternelRetour_01.webp",
        imgTxt: "L'éternel retour...",
        imgGrp: "listImgFlip15"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LaBelleEtLaBete_01.webp",
        imgTxt: "La belle et la bête.",
        imgGrp: "listImgFlip16"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LeCouteauAztek_01.webp",
        imgTxt: "Le couteau Aztèque",
        imgGrp: "listImgFlip16"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LesHommesOiseauxDePaques_01.webp",
        imgTxt: "Les hommes-oiseaux de Pâques",
        imgGrp: "listImgFlip16"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_LeSupremeTrioCeleste_01.webp",
        imgTxt: "Le suprème trio céleste",
        imgGrp: "listImgFlip16"
    },
    {
        imgScr: "/assets/img/img_art_box3/100_OrdonneEtJObeis_01.webp",
        imgTxt: "Ordonne et j'obéis",
        imgGrp: "listImgFlip16"
    }
]

export default DESSINS_S;