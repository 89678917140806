import React, { Component, FunctionComponent, useEffect, useState, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './pages/main000m.css';
import './pages/main-media000m.css';
import ModuleMenu from './pages/module_menu';
import ModuleParallaxe from './components/module_parallaxe';
import PageN1 from './pages/page_n1';
import ModuleHeader from './pages/module_header';

import ModuleIleYokai0 from './components/module_ile_yokai0';
import ModuleExodeRural from './components/module_exode_rural';
import ModuleFujiSan from './components/module_fuji_san';
import ModuleFinMonde from './components/module_fin_monde';
import ModuleStylePolaire from './components/module_style_polaire';
import ModuleIleVersaille from './components/module_ile_versaille';
import ModuleMeteoBiblique from './components/module_meteo_biblique';
import ModuleGrueJaune from './components/module_grue_jaune';
import ModuleMemoryNoir from './components/module_memory_noir';
import ModuleRoiMages from './components/module_rois_mages';
import ModuleCarreJap from './components/module_carre_jap';
import ModulePaysageOcean  from './components/module_paysage_ocean';
import ModuleBestiolesRoche from './components/module_bestioles_roche';
import ModuleIleYokai from './components/module_ile_yokai';
import ModuleBatailleKaiju from './components/module_bataille_kaiju';
import ModuleSansIssue from './components/module_sans_issue';

//  {module : <ModuleIleVersaille/>, ancre:"IV00",descCt:"Versaille"}, 

const  tabModule0 = [ 
  {module : <ModuleBestiolesRoche/>, ancre:"VR",descCt:"Carverne"}, 
  {module : <ModulePaysageOcean/>, ancre:"PO",descCt:"Plage"},
  {module : <ModuleMemoryNoir/>, ancre:"MN",descCt:"Memoire"}, 
  {module : <ModuleCarreJap/>, ancre:"CJ",descCt:"Carré"},
  {module : <ModuleRoiMages/>, ancre:"RM",descCt:"Roi"}, 
  {module : <ModuleGrueJaune/>, ancre:"GJ",descCt:"Machine"},
  {module : <ModuleMeteoBiblique/>, ancre:"MB",descCt:"Victoire"}, 
  {module : <ModuleFinMonde/>, ancre:"FM",descCt:"Apocalypse"},
  {module : <ModuleIleYokai0/>, ancre:"IY",descCt:"Etang"}, 
  {module : <ModuleStylePolaire/>, ancre:"SP",descCt:"Polaire"},
  {module : <ModuleExodeRural/>, ancre:"ER",descCt:"Soucoupe"},
  {module : <ModuleBatailleKaiju/>,ancre:"BK",descCt:"kaiju"},
  {module : <ModuleFujiSan/>, ancre:"FS",descCt:"Fuji San"},
{module : <ModuleSansIssue/>, ancre:"SI",descCt:"Sans Issue"}
]

const tabModule = tabModule0.map(value => ({ value, sort: Math.random() }))
.sort((a, b) => a.sort - b.sort)
.map(({ value }) => value)

type Props = {
};

const App: FunctionComponent = () => {
 
  return (<>
  <ModuleHeader/>
  <ModuleMenu  tab={tabModule}/>
  <ModuleParallaxe/> 
    <div className="mega_cadre mega_bloc01"  onContextMenu={(e)=>  {e.preventDefault(); return false;}} style={{display:'bloc'}}>
    <PageN1 tab={tabModule}/>
   </div> 
   </>
  );
}
  
export default App;