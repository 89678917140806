import React, { FunctionComponent, useState } from 'react';
import Carre00 from "../assets/img/img_space/FondVide800x720.gif"
import './module_carre_jap.css';

type Props = {
};

const tabPath1CJ00 = [
  {a1:1,a2:2,a3:3,b1:8,b2:9,b3:4,c1:7,c2:6,c3:5},
  {a1:1,a2:2,a3:3,b1:6,b2:5,b3:4,c1:7,c2:8,c3:9},
  {a1:1,a2:2,a3:3,b1:8,b2:7,b3:4,c1:9,c2:6,c3:5}
]

const rotTabCJ = (tab:[]) => {
  const newtab = {a1:tab.c1,a2:tab.b1,a3:tab.a1,b1:tab.c2,b2:tab.b2,b3:tab.a2,c1:tab.c3,c2:tab.b3,c3:tab.a3}
  return newtab
}

const mirTabCJ = (tab:[]) => {
  const newtab = {a1:tab.c1,a2:tab.c2,a3:tab.c3,b1:tab.b1,b2:tab.b2,b3:tab.b3,c1:tab.a1,c2:tab.a2,c3:tab.a3}
  return newtab
}

const invTabCJ = (tab:[]) => {
  const newtab = {a1:(10 - tab.a1),a2:(10 - tab.a2),a3:(10 - tab.a3),b1:(10 - tab.b1),b2:(10 - tab.b2),b3:(10 - tab.b3),c1:(10 - tab.c1),c2:(10 - tab.c2),c3:(10 - tab.c3)}
 //const newtab =  tab.map((x) => 10 - x )
  return newtab
}

const ModuleCarreJap: FunctionComponent<Props> = () => {

  const tabTestCJ = () => {
    const tab = tabPath1CJ00[Math.floor(Math.random()*tabPath1CJ00.length)];
    const r: number = Math.floor(Math.random() * 4);
    const m: number = Math.floor(Math.random() * 2);
    const i: number = Math.floor(Math.random() * 2);
    const newTab = (( i == 1)? invTabCJ(tab):tab)
    if(r == 1) {return rotTabCJ((( m == 1)? mirTabCJ(newTab):newTab))}
    else if(r == 2) {return rotTabCJ(rotTabCJ((( m == 1)? mirTabCJ(newTab):newTab)))}
    else if(r == 3) {return rotTabCJ(rotTabCJ(rotTabCJ((( m == 1)? mirTabCJ(newTab):newTab))))}
    else {return (( m == 1)? mirTabCJ(newTab):newTab)}
    }
  
  const gentabTestCJ = () => {
    const tabTestCJ1= [tabTestCJ()]
  const tabTestCJ2= [
  { idd:"a1", pos:tabTestCJ1[0].a1, CCScadreCJ1: "cadreCJ1_0"+tabTestCJ1[0].a1, CSScadreCJ11: "cadreCJ11_0"+tabTestCJ1[0].a1, CSSZIndexCJ11:"cadreCJ1__content"},
  { idd:"a2", pos:tabTestCJ1[0].a2,CCScadreCJ1: "cadreCJ1_0"+tabTestCJ1[0].a2, CSScadreCJ11: "cadreCJ11_0"+tabTestCJ1[0].a2, CSSZIndexCJ11:"cadreCJ1__content" },
  { idd:"a3", pos:tabTestCJ1[0].a3,CCScadreCJ1: "cadreCJ1_0"+tabTestCJ1[0].a3, CSScadreCJ11: "cadreCJ11_0"+tabTestCJ1[0].a3 , CSSZIndexCJ11:"cadreCJ1__content"},
  { idd:"b1", pos:tabTestCJ1[0].b1,CCScadreCJ1: "cadreCJ1_0"+tabTestCJ1[0].b1, CSScadreCJ11: "cadreCJ11_0"+tabTestCJ1[0].b1 , CSSZIndexCJ11:"cadreCJ1__content"},
  { idd:"b2", pos:tabTestCJ1[0].b2,CCScadreCJ1: "cadreCJ1_0"+tabTestCJ1[0].b2, CSScadreCJ11: "cadreCJ11_0"+tabTestCJ1[0].b2 , CSSZIndexCJ11:"cadreCJ1__content"},
  { idd:"b3", pos:tabTestCJ1[0].b3,CCScadreCJ1: "cadreCJ1_0"+tabTestCJ1[0].b3, CSScadreCJ11: "cadreCJ11_0"+tabTestCJ1[0].b3 , CSSZIndexCJ11:"cadreCJ1__content"},
  { idd:"c1", pos:tabTestCJ1[0].c1,CCScadreCJ1: "cadreCJ1_0"+tabTestCJ1[0].c1, CSScadreCJ11: "cadreCJ11_0"+tabTestCJ1[0].c1, CSSZIndexCJ11:"cadreCJ1__content" },
  { idd:"c2", pos:tabTestCJ1[0].c2,CCScadreCJ1: "cadreCJ1_0"+tabTestCJ1[0].c2, CSScadreCJ11: "cadreCJ11_0"+tabTestCJ1[0].c2, CSSZIndexCJ11:"cadreCJ1__content" },
  { idd:"c3", pos:tabTestCJ1[0].c3,CCScadreCJ1: "cadreCJ1_0"+tabTestCJ1[0].c3, CSScadreCJ11: "cadreCJ11_0"+tabTestCJ1[0].c3, CSSZIndexCJ11:"cadreCJ1__content" }
  ]
  return tabTestCJ2
}

const [tabPathCJ, setTabPathCJ] = useState(gentabTestCJ())
const [tagPathCJ, setTagPathCJ] = useState(1)
const [CSSBtCJ, setCSSBtCJ] = useState ("none")
const [CCSTagCJ, setCCSTagCJ] = useState(true)
   
const actCJ = (actIdd:number) => {
if(CCSTagCJ == true){
  if (actIdd == 1){ 
    setTabPathCJ(tabPathCJ.map(tab => tab.pos == 1 ? { ...tab, CSSZIndexCJ11: "cadreCJ1__content2" } : tab ));
    setTagPathCJ(tagPathCJ+1)
  }
  else if (tagPathCJ == 9){
    setTabPathCJ(tabPathCJ.map(tab => tab.pos == 9 ? { ...tab, CSSZIndexCJ11: "cadreCJ1__content2" } : tab ));
    setTagPathCJ(1)
    setCCSTagCJ(false)
    setTimeout(() => {
      setCSSBtCJ("block")
    }, 2000)
  }
  else if (tagPathCJ == actIdd){
    setTabPathCJ(tabPathCJ.map(tab => tab.pos == tagPathCJ ? { ...tab, CSSZIndexCJ11: "cadreCJ1__content2" } : tab));
    setTagPathCJ(tagPathCJ+1)}
  else {
    setTagPathCJ(1) 
    setTabPathCJ(tabPathCJ.map(tab => tab.CSSZIndexCJ11 == "cadreCJ1__content2" ? { ...tab, CSSZIndexCJ11: "cadreCJ1__content" } : tab ));
    
  }
}
}


const rejouerCJ = () => {
  const tabTestCJ3= [tabTestCJ()]
const tabTestCJ4= [
{ idd:"a1", pos:tabTestCJ3[0].a1, CCScadreCJ1: "cadreCJ1_0"+tabTestCJ3[0].a1, CSScadreCJ11: "cadreCJ11_0"+tabTestCJ3[0].a1, CSSZIndexCJ11:"cadreCJ1__content"},
{ idd:"a2", pos:tabTestCJ3[0].a2,CCScadreCJ1: "cadreCJ1_0"+tabTestCJ3[0].a2, CSScadreCJ11: "cadreCJ11_0"+tabTestCJ3[0].a2, CSSZIndexCJ11:"cadreCJ1__content" },
{ idd:"a3", pos:tabTestCJ3[0].a3,CCScadreCJ1: "cadreCJ1_0"+tabTestCJ3[0].a3, CSScadreCJ11: "cadreCJ11_0"+tabTestCJ3[0].a3 , CSSZIndexCJ11:"cadreCJ1__content"},
{ idd:"b1", pos:tabTestCJ3[0].b1,CCScadreCJ1: "cadreCJ1_0"+tabTestCJ3[0].b1, CSScadreCJ11: "cadreCJ11_0"+tabTestCJ3[0].b1 , CSSZIndexCJ11:"cadreCJ1__content"},
{ idd:"b2", pos:tabTestCJ3[0].b2,CCScadreCJ1: "cadreCJ1_0"+tabTestCJ3[0].b2, CSScadreCJ11: "cadreCJ11_0"+tabTestCJ3[0].b2 , CSSZIndexCJ11:"cadreCJ1__content"},
{ idd:"b3", pos:tabTestCJ3[0].b3,CCScadreCJ1: "cadreCJ1_0"+tabTestCJ3[0].b3, CSScadreCJ11: "cadreCJ11_0"+tabTestCJ3[0].b3 , CSSZIndexCJ11:"cadreCJ1__content"},
{ idd:"c1", pos:tabTestCJ3[0].c1,CCScadreCJ1: "cadreCJ1_0"+tabTestCJ3[0].c1, CSScadreCJ11: "cadreCJ11_0"+tabTestCJ3[0].c1, CSSZIndexCJ11:"cadreCJ1__content" },
{ idd:"c2", pos:tabTestCJ3[0].c2,CCScadreCJ1: "cadreCJ1_0"+tabTestCJ3[0].c2, CSScadreCJ11: "cadreCJ11_0"+tabTestCJ3[0].c2, CSSZIndexCJ11:"cadreCJ1__content" },
{ idd:"c3", pos:tabTestCJ3[0].c3,CCScadreCJ1: "cadreCJ1_0"+tabTestCJ3[0].c3, CSScadreCJ11: "cadreCJ11_0"+tabTestCJ3[0].c3, CSSZIndexCJ11:"cadreCJ1__content" }
]
setTabPathCJ(tabTestCJ4);
setCCSTagCJ(true)
setCSSBtCJ("none")
}

  return <>
      <div className="cadreCJ" style={{ width: "100%" }}>
      {tabPathCJ.map((elt) => (
      <div className={"cadreCJ1 "+(elt.CCScadreCJ1)} id={elt.idd}>
          <img className="imgVideCJ" src={Carre00} />
          <div className="div28 cadreCJ1__left"></div>
          <div className="div28 cadreCJ1__right"></div>
          <div className="div28 cadreCJ1__top"></div>
          <div className="div28 cadreCJ1__bottom"></div>
          <div className={(elt.CSSZIndexCJ11)+" "+(elt.CSScadreCJ11)} onMouseOver={x => actCJ(elt.pos)}>
            &nbsp;
          </div>
        </div>
        ))}
            <div className="cadreCJ_msg" style={{ display: CSSBtCJ }}>
        <div className="cadreCJ7_msg">
          <div className="cadreCJ77_msg">
            <h2 className="winner000"><span>Bravo !<br/>C'est le bout du chemin.</span></h2>
            <button className="reponse000" onClick={rejouerCJ}>Rejouer?</button>
          </div>
        </div>
      </div>
      </div>
  </>
}

export default ModuleCarreJap;