import React, { FunctionComponent, useState, useEffect } from 'react';
import ModuleSousMenu from './module_sous_menu';
import DelaiAff from '../helpers/delai_aff';
import './module_menu.css';

type Props = {
  tab:[]
};

let delai:number = 200;
const delaiDecpt = () =>{
  delai = delai + 100;
  return delai;
}

const ModuleMenu2: FunctionComponent<Props> = ({tab}) => {



const [topNavM000, settopNavM000] = useState<string>("topNavM000")

const axtMenu000 = () => {
    if(topNavM000 === "topNavM000"){
        settopNavM000("topNavM000 responsive")
    }
    else{
        settopNavM000("topNavM000")
    }
}

  return <>

<div className={topNavM000} id="mytopNavM000">
  <div className="topNavM000bg">
  <a className="top_navM000" href="#accueil"  onClick={axtMenu000}><span className="top_navM000H">Home</span></a>
  {/*<a className="top_navM000" href="#FT"><span className="top_navM000">Contact</span></a>*/}
  {tab.map((elt) => (
          (<a className="top_navM000" href={`#${elt.ancre}`}><span className="top_navM000"  style={{display:DelaiAff(delaiDecpt())}}   onClick={axtMenu000}>{elt.descCt}</span></a>)
          ))}

  
  <a  className="icon" onClick={axtMenu000}><span className="top_navM000">&#9776;</span></a>
  </div>
</div>

  </>



}

export default ModuleMenu2;