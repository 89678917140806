

import React, { FunctionComponent, useState, useEffect } from 'react';
import logo from "../assets/img/img_t/LogoMine2Sly.webp"
import './module_txt_accueil.css';
type Props = {
}



const ModuleTxtAccueil: FunctionComponent<Props> = () => {

return<>
    <div className="bande_large00 super_cadre11" style={{marginBottom:"2%",marginTop:"10%"}}>
        <div className='idAncre00'  id="accueil"></div>
        <div className="cadre11">
                <div className="cadre111" style={{ padding: "2em" }}>
                    <img src={logo} className="image_logo11"/>
                        <h2 className="h2_txt11">Graphiste Print & Web.</h2>
                        <h2 className="h2_txt11">Développeur Web Front.</h2>
                        <h2 className="h2_txt11">Plus de 15 ans d'expérience dans le e-commerce.</h2>
                        <h2 className="h2_txt11">Je vous invite à tranquillement vous balader sur mon site web et découvrir les dessins et les animations de mon cru. </h2>
                        <h2 className="h2_txt11">L'intégralité de mon site Internet a été codé par mes soins avec la techologie React JS  (100% responsive) .</h2>
                        <h2 className="h2_txt11" style={{float:"right"}}>Sly.</h2>
                </div>
        </div>
    </div>
</>

}

export default  ModuleTxtAccueil;