import React, { FunctionComponent, useState } from 'react';
import './module_style_polaire.css';
import fondVide from "../assets/img/img_space/FondVide800x720.gif"
import Montagn03_fond from "../assets/img/img_space/Montagn03_fond.webp"

type Props = {
  };
  
const ModuleStylePolaire: FunctionComponent<Props> = () => {

    const [delaiSP, setDelaiSP] = useState<boolean>(true);
    const [CSSPisteSP0, setCSSPisteSP0] = useState<string>("none");
    const [CSSPersoSP1, setCSSPersoSP1] = useState<string>("none");
    const [CSSPersoSP2, setCSSPersoSP2] = useState<string>("none");
    const [CSSPersoSP3, setCSSPersoSP3] = useState<string>("none");
    const [CSSPersoSP4, setCSSPersoSP4] = useState<string>("none");
    const [CSSPersoSP5, setCSSPersoSP5] = useState<string>("none");
    const [CSSPersoSP6, setCSSPersoSP6] = useState<string>("none");
    const [CSSBtSP1, setCSSBtSP1] = useState<string>("..");
    const [CSSBtSP2, setCSSBtSP2] = useState<string>("..");
    const [CSSBtSP3, setCSSBtSP3] = useState<string>("..");
    const [CSSBtSP4, setCSSBtSP4] = useState<string>("..");
    const [CSSBtSP5, setCSSBtSP5] = useState<string>("..");
    const [CSSBtSP6, setCSSBtSP6] = useState<string>("..");

    const initCCSBtSP = () => {
        setCSSBtSP1("#FF0000");
        setCSSBtSP2("#FF0000");
        setCSSBtSP3("#FF0000");
        setCSSBtSP4("#FF0000");
        setCSSBtSP5("#FF0000");
        setCSSBtSP6("#FF0000");
    }

    const greyCCSBtSP = () => {
        setCSSBtSP1("#000000");
        setCSSBtSP2("#000000");
        setCSSBtSP3("#000000");
        setCSSBtSP4("#000000");
        setCSSBtSP5("#000000");
        setCSSBtSP6("#000000");
    }

    const CSSpolaireSP = (a: number) => {
        if (a == 1) {
            if (delaiSP == true) {
                setCSSPisteSP0("block");
                setCSSPersoSP1("block");
                setDelaiSP(false);
                greyCCSBtSP();
                setCSSBtSP1("#48EFF7");
                setTimeout(() => {
                    setCSSPisteSP0("none");
                    setCSSPersoSP1("none");
                    setDelaiSP(true);
                    initCCSBtSP();
                }, 8000)
            }
        }

        if (a == 2) {
            if (delaiSP == true) {
                setCSSPisteSP0("block");
                setCSSPersoSP2("block");
                setDelaiSP(false);
                greyCCSBtSP();
                setCSSBtSP2("#48EFF7");
                setTimeout(() => {
                    setCSSPisteSP0("none");
                    setCSSPersoSP2("none");
                    setDelaiSP(true);
                    initCCSBtSP();
                }, 8000)
            }
        }

        if (a == 3) {
            if (delaiSP == true) {
                setCSSPisteSP0("block");
                setCSSPersoSP3("block");
                setDelaiSP(false);
                greyCCSBtSP();
                setCSSBtSP3("#48EFF7");
                setTimeout(() => {
                    setCSSPisteSP0("none");
                    setCSSPersoSP3("none");
                    setDelaiSP(true);
                    initCCSBtSP();
                }, 8000)
            }
        }

        if (a == 4) {
            if (delaiSP == true) {
                setCSSPisteSP0("block");
                setCSSPersoSP4("block");
                setDelaiSP(false);
                greyCCSBtSP();
                setCSSBtSP4("#48EFF7");
                setTimeout(() => {
                    setCSSPisteSP0("none");
                    setCSSPersoSP4("none");
                    setDelaiSP(true);
                    initCCSBtSP();
                }, 8000)
            }
        }

        if (a == 5) {
            if (delaiSP == true) {
                setCSSPisteSP0("block");
                setCSSPersoSP5("block");
                setDelaiSP(false);
                greyCCSBtSP();
                setCSSBtSP5("#48EFF7");
                setTimeout(() => {
                    setCSSPisteSP0("none");
                    setCSSPersoSP5("none");
                    setDelaiSP(true);
                    initCCSBtSP();
                }, 8000)
            }
        }

        if (a == 6) {
            if (delaiSP == true) {
                setCSSPisteSP0("block");
                setCSSPersoSP6("block");
                setDelaiSP(false);
                greyCCSBtSP();
                setCSSBtSP6("#48EFF7");
                setTimeout(() => {
                    setCSSPisteSP0("none");
                    setCSSPersoSP6("none");
                    setDelaiSP(true);
                    initCCSBtSP();
                }, 8000)
            }
        }
    }

    return <>
        <div className="cadreSP" style={{ width: "100%" }}>
                <div className="cadreSP_pl"></div>
                <img className="imgFondSP" src={fondVide} />
                <div className="cadreSP2">
                    <div className="pisteSP" style={{ display: CSSPisteSP0 }}>
                    </div>
                    <div className="persoSP_01" style={{ display: CSSPersoSP1 }}>
                        <div className="persoSP_01c">
                        </div>
                    </div>
                    <div className="persoSP_02" style={{ display: CSSPersoSP2 }}>
                        <div className="persoSP_02c">
                        </div>
                    </div>
                    <div className="persoSP_03" style={{ display: CSSPersoSP3 }}>
                        <div className="persoSP_03c">
                        </div>
                    </div>
                    <div className="persoSP_04" style={{ display: CSSPersoSP4 }}>
                    </div>
                    <div className="persoSP_05" style={{ display: CSSPersoSP5 }}>
                    </div>
                    <div className="persoSP_06" style={{ display: CSSPersoSP6 }}>
                    </div>
                    <div className="neigeSP" style={{ display: CSSPisteSP0 }}>
                    </div>
                    <img className="imgFondSP" src={Montagn03_fond} />
                </div>
                <div className="cadreSP2m">
                    <div className="btSPm">
                        <div className="btSPmm" style={{ backgroundColor: CSSBtSP1 }} onClick={a => CSSpolaireSP(1)}></div>
                    </div>
                    <div className="btSPm">
                        <div className="btSPmm" style={{ backgroundColor: CSSBtSP2 }} onClick={a => CSSpolaireSP(2)}></div>
                    </div>
                    <div className="btSPm">
                        <div className="btSPmm" style={{ backgroundColor: CSSBtSP3 }} onClick={a => CSSpolaireSP(3)}></div>
                    </div>
                    <div className="btSPm">
                        <div className="btSPmm" style={{ backgroundColor: CSSBtSP4 }} onClick={a => CSSpolaireSP(4)}></div>
                    </div>
                    <div className="btSPm">
                        <div className="btSPmm" style={{ backgroundColor: CSSBtSP5 }} onClick={a => CSSpolaireSP(5)}></div>
                    </div>
                    <div className="btSPm">
                        <div className="btSPmm" style={{ backgroundColor: CSSBtSP6 }} onClick={a => CSSpolaireSP(6)}></div>
                    </div>
                </div>
            </div>
    </>
}

export default ModuleStylePolaire;
