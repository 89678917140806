import React, { FunctionComponent, useState, useLayoutEffect, useRef, useCallback} from 'react';
import ReactDOM from 'react-dom';
import './module_bestioles_roche.css';
import Carre00 from "../assets/img/img_space/FondVide800x720.gif"
import Fond_BoutonV000 from "../assets/img/img_space/Fond_BoutonV000.gif"
import Fond_BoutonCarre000 from "../assets/img/img_space/Fond_BoutonCarre000.gif"

type Props = {
};


const ModuleBestiolesRoche: FunctionComponent<Props> = () => {

const [CCSOmbreTopBR, setCCSOmbreTopBR] = useState<string>("-50%")
const [CCSOmbreLeftBR, setCCSOmbreLeftBR] = useState<string>("-50%")
const [CCSOmbreLumiereBR, setCCSOmbreLumiereBR] = useState<string>("block")
const [CCSTagBR, setCCSTagBR] = useState<boolean>(true)


const ref1 = useRef(null);
const ref2 = useRef(null);

const tagBRLumiere = ((e: React.FormEvent<HTMLFormElement>) => {
    if (CCSTagBR == true) {
        setCCSOmbreLumiereBR("none");
      setCCSTagBR(false);
    }
    else {
        setCCSOmbreLumiereBR("block");
      setCCSTagBR(true);
    }
  })


const actBR = (event) => { 
  setTimeout(() => {
const lgOmbreGR = ref2.current.clientWidth;
  const htOmbreGR = ref2.current.clientHeight;
 /* const XRCadreGR = ref1.current.offsetLeft;
  const YRCadreGR = ref1.current.offsetTop;
  const XACadreGR = ref1.current.offsetParent.offsetLeft;
  const YACadreGR = ref1.current.offsetParent.offsetTop;
  const XAACadreGR = ref1.current.offsetParent.offsetParent.offsetLeft;
  const YAACadreGR = ref1.current.offsetParent.offsetParent.offsetTop;
  const lgCadreGR = ref1.current.offsetWidth;
  const htCadreGR = ref1.current.offsetHeight;*/

const element1=ReactDOM.findDOMNode(ref1.current);
const rect = element1.getBoundingClientRect(); 
setCCSOmbreLeftBR(`${(((event.clientX)-(lgOmbreGR/2))-rect.x)}px`);
setCCSOmbreTopBR(`${(((event.clientY)-(htOmbreGR/2))-rect.y)}px`);},250)
  };




return <>

<div className="cadreBR" style={{ width: "100%",overflow:"hidden" }}>
<div className="cadreBR_pl"></div>
<img className="imgFondBR" src={Carre00} />
<div className="cadreBR1" ref={ref1}>
</div>
    <div className="mvtBeteBR01a">
        <div className="beteBR01">
        <img className="imgFondBR" src={Carre00} />
        </div>
    </div>
    <div className="mvtBeteBR01b">
        <div className="beteBR01">
        <img className="imgFondBR" src={Carre00} />
        </div>
    </div>
    <div className="mvtBeteBR01c">
        <div className="beteBR01">
        <img className="imgFondBR" src={Carre00} />
        </div>
    </div>
    <div className="mvtBeteBR01d">
        <div className="beteBR01">
        <img className="imgFondBR" src={Carre00} />
        </div>
    </div>
    <div className="mvtBeteBR02">
        <div className="beteBR02">
        <img className="imgFondBR" src={Carre00} />
        </div>
    </div>
   <div className="mvtBeteBR03">
        <div className="beteBR03">
        <img className="imgFondBR" src={Carre00} />
        </div>
    </div>
    <div className="mvtBeteBR04">
        <div className="beteBR04">
        <img className="imgFondBR" src={Carre00} />
        </div>
    </div>
    <div className="mvtBeteBR05">
        <div className="beteBR05">
        <img className="imgFondBR" src={Carre00} />
        </div>
    </div>
    <div className="mvtBeteBR06">
        <div className="beteBR06">
        <img className="imgFondBR" src={Carre00} />
        </div>
    </div>
    <div className="mvtBeteBR07">
        <div className="beteBR07">
        <img className="imgFondBR" src={Carre00} />
        </div>
    </div>
    <div className="mvtBeteBR08">
        <div className="beteBR08">
        <img className="imgFondBR" src={Carre00} />
        </div>
    </div>
    <div className="mvtBeteBR09">
        <div className="beteBR09">
        <img className="imgFondBR" src={Carre00} />
        </div>
    </div>
    <div className="mvtBeteBR10">
        <div className="beteBR10">
        <img className="imgFondBR" src={Carre00} />
        </div>
    </div>
    <div className="ombreLumiereBR" style={{top:CCSOmbreTopBR,left:CCSOmbreLeftBR}} onMouseMove={actBR}  ref={ref2}>
        <img className="imgFondBR" src={Carre00} />
        </div>
        <div className="ombreBR" style={{display:CCSOmbreLumiereBR}}>
        <img className="imgFondBR" src={Carre00} />
        </div>
        <div className="cadreBR3a">
          <div className="cadreBR3aa" >
            <img className="imgFondBR" src={Fond_BoutonV000} />
            <input type="checkbox" id="chkBR_lumiere01" checked={CCSTagBR} onClick={e => tagBRLumiere(e)} />
            <label id="button2aBR" htmlFor="chkBR_lumiere01">
              <div id="knob_lumiere01">
                <img className="imgFondBR" src={Fond_BoutonCarre000} />
              </div>
            </label>
          </div>
        </div>
</div>

</>
}

export default ModuleBestiolesRoche;