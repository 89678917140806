

import React, { FunctionComponent, useState, useEffect } from 'react';

import './module_txt_accueil.css';
type Props = {
}



const ModuleTxtFooter: FunctionComponent<Props> = () => {

return<>
    <div className="bande_large00 super_cadre11" style={{marginTop:"2%",marginBottom:"2%"}}>
    <div className='idAncre00'  id="FT"></div>
        <div className="cadre11">
                <div className="cadre111" style={{ padding: "2em" }}>
             <h2 className="h2_txt11">Si mon style graphique et coloré vous plait</h2>
                        <h2 className="h2_txt11">ou si simplement vous souhaitez me faire parvenir un message.</h2>
                        <h2 className="h2_txt11">Alors n'hésitez pas à me contacter : mine2sly@gmail.com.</h2>
                        <h2 className="h2_txt11">Je me ferais un plaisir de vous répondre rapidement.</h2>
                        <h2 className="h2_txt11">Je peux réaliser des flyers, des invitations, des  faire-parts, des affiches et autres tracts pour des évenements, aniversaires, concerts, fêtes de quartiers ou de villages....</h2>
                        <h2 className="h2_txt11" style={{float:"right"}}>Sly.</h2>
                </div>
        </div>
    </div>
</>

}

export default  ModuleTxtFooter;