import React, { FunctionComponent, useState } from 'react';
import './module_meteo_biblique.css';
import BoutonFS01_vide from  "../assets/img/img_space/BoutonFS01_vide.gif"
import FondVid from  "../assets/img/img_space/FondKheopVid.gif"
import FondVidCase from  "../assets/img/img_space/FondKheopVidCase.gif"
import FondK01 from  "../assets/img/img_space/FondKhops01.webp"
import bugVide from  "../assets/img/img_space/FondKhops01_bugvid.gif"
import VictoireMvt00_vide from  "../assets/img/img_space/800MB_VictoireMvt00_vide.gif"
type Props = {
};

const ModuleMeteoBiblique: FunctionComponent<Props> = () => {

  const [CSScadreMB, setCSScadreMB] = useState<string>("cadreMB66  topCadreMN0");

  const [CSSBtMB1, setCSSBtMB1] = useState<string>("100%");
  const [CSSBtMB2, setCSSBtMB2] = useState<string>("0%");
  const [CSSBtMB3, setCSSBtMB3] = useState<string>("0%");
  const [CSSBtMB4, setCSSBtMB4] = useState<string>("0%");
  const [CSSBtMB5, setCSSBtMB5] = useState<string>("0%");
  const [CSSBtMB6, setCSSBtMB6] = useState<string>("0%");

  const initCSSBtMB = () => {
    setCSSBtMB1("0%")
    setCSSBtMB2("0%")
    setCSSBtMB3("0%")
    setCSSBtMB4("0%")
    setCSSBtMB5("0%")
    setCSSBtMB6("0%")
  }

  const actMBb = (a: number) => {
    if (a == 1) {
      setCSScadreMB("cadreMB66 topCadreMN0");
      initCSSBtMB()
      setCSSBtMB1("100%")
    }
    if (a == 2) {
      setCSScadreMB("cadreMB66 topCadreMN100");
      initCSSBtMB()
      setCSSBtMB2("100%")
    }
    if (a == 3) {
      setCSScadreMB("cadreMB66 topCadreMN200");
      initCSSBtMB()
      setCSSBtMB3("100%")
    }
    if (a == 4) {
      setCSScadreMB("cadreMB66 topCadreMN300");
      initCSSBtMB()
      setCSSBtMB4("100%")
    }
    if (a == 5) {
      setCSScadreMB("cadreMB66 topCadreMN400");
      initCSSBtMB()
      setCSSBtMB5("100%")
    }
    if (a == 6) {
      setCSScadreMB("cadreMB66 topCadreMN500");
      initCSSBtMB()
      setCSSBtMB6("100%")
    }
  }

  return <>
      <div className="cadreMB" style={{ width: "100%" }}>
        <div className="cadreMB_pl"></div>
        <img className="imgFondMB" src={FondVid} />
        <div className="cadreMB6_bouton">
          <div id="owwMN_la1" data-owwMN-id="owwMN1" className="b26_la btMN_la" style={{ backgroundPosition: CSSBtMB1 }} onClick={a => actMBb(1)}>
            <img className="imgFondMB" src={BoutonFS01_vide} />
          </div>
          <div id="owwMN_la2" data-owwMN-id="owwMN2" className="b26_la btMN_la" style={{ backgroundPosition: CSSBtMB2 }} onClick={a => actMBb(2)}>
            <img className="imgFondMB" src={BoutonFS01_vide} />
          </div>
          <div id="owwMN_la3" data-owwMN-id="owwMN3" className="b26_la btMN_la" style={{ backgroundPosition: CSSBtMB3 }} onClick={a => actMBb(3)}>
            <img className="imgFondMB" src={BoutonFS01_vide} />
          </div>
          <div id="owwMN_la4" data-owwMN-id="owwMN4" className="b26_la btMN_la" style={{ backgroundPosition: CSSBtMB4 }} onClick={a => actMBb(4)}>
            <img className="imgFondMB" src={BoutonFS01_vide} />
          </div>
          <div id="owwMN_la5" data-owwMN-id="owwMN5" className="b26_la btMN_la" style={{ backgroundPosition: CSSBtMB5 }} onClick={a => actMBb(5)}>
            <img className="imgFondMB" src={BoutonFS01_vide} />
          </div>
          <div id="owwMN_la6" data-owwMN-id="owwMN6" className="b26_la btMN_la" style={{ backgroundPosition: CSSBtMB6 }} onClick={a => actMBb(6)}>
            <img className="imgFondMB" src={BoutonFS01_vide} />
          </div>
        </div>

        <div className="cadreMB6_FondK">
          <img className="imgFondMB" src={FondK01} />
        </div>

        <div className="cadreMB6">
          <img className="imgFondMB" src={FondVidCase} />
          <div className={CSScadreMB}>

            <div className="owwMN" style={{ overflow: "hidden" }} id="owwMN1">
              <img className="imgFondMB" style={{ backgroundColor: "gold", opacity: "0.33" }} src={FondVidCase} />
              <div className="cadreMB66_01a"></div>
            </div>

            <div className="owwMN" style={{ overflow: "hidden" }} id="owwMN2">
              <img className="imgFondMB" style={{ backgroundColor: "green", opacity: "0.33" }} src={FondVidCase} />
              <div className="cadreMB66_02a">
                <div className="cadreMB66_02bug">
                  <img className="imgFondMB" src={bugVide} />
                </div>
              </div>
              <div className="cadreMB66_02b">
                <div className="cadreMB66_02bug">
                  <img className="imgFondMB" src={bugVide} />
                </div>
              </div>
              <div className="cadreMB66_02c">
                <div className="cadreMB66_02bug">
                  <img className="imgFondMB" src={bugVide} />
                </div>
              </div>
              <div className="cadreMB66_02d">
                <div className="cadreMB66_02bug">
                  <img className="imgFondMB" src={bugVide} />
                </div>
              </div>
              <div className="cadreMB66_02e">
                <div className="cadreMB66_02bug">
                  <img className="imgFondMB" src={bugVide} />
                </div>
              </div>
            </div>

            <div className="owwMN" style={{ overflow: "hidden" }} id="owwMN3">
              <img style={{ backgroundColor: "red", opacity: "0.33" }} className="imgFondMB" src={FondVidCase} />
              <div className="cadreMB66_04a"></div>
              <div className="cadreMB66_04b"></div>
              <div className="cadreMB66_04c"></div>
              <div className="cadreMB66_04d"></div>
              <div className="cadreMB66_04e"></div>
              <div className="cadreMB66_04f"></div>
            </div>

            <div className="owwMN" style={{ overflow: "hidden" }} id="owwMN4">
              <img style={{ backgroundColor: "purple", opacity: "0.33" }} className="imgFondMB" src={FondVidCase} />
              <div className="cadreMB66_03a"></div>
              <div className="cadreMB66_03b"></div>
              <div className="cadreMB66_03c"></div>
              <div className="cadreMB66_03d"></div>
            </div>

            <div className="owwMN" style={{ overflow: "hidden" }} id="owwMN5">
              <img style={{ backgroundColor: "blue", opacity: "0.33" }} className="imgFondMB" src={FondVidCase} />
              <div className="cadreMB66_05a"></div>
              <div className="cadreMB66_05b"></div>
              <div className="cadreMB66_05c"></div>
            </div>

            <div className="owwMN" style={{ overflow: "hidden" }} id="owwMN6">
              <img style={{ backgroundColor: "black", opacity: "0.66" }} className="imgFondMB" src={FondVidCase} />
              <div className="cadreMB66_06a"></div>
              <div className="cadreMB66_06b"></div>
              <div className="cadreMB66_06c"></div>
            </div>

          </div>
        </div>
        <div className="cadreMB6_nana">
          <img className="imgFondMB" src={VictoireMvt00_vide} />
        </div>
      </div>
  </>
}

export default ModuleMeteoBiblique;
