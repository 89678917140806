import React, { FunctionComponent, useState } from 'react';
import fondVide from "../assets/img/img_space/FondVide800x720.gif"
import Carre00 from "../assets/img/img_vide/Carre00.gif"
import './module_bataille_kaiju.css';

type Props = {
};

const TabBKBKMonstre0 = [
  { monstreBK: "monstreBK_01" },
  { monstreBK: "monstreBK_02" },
  { monstreBK: "monstreBK_03" },
  { monstreBK: "monstreBK_04" },
  { monstreBK: "monstreBK_05" },
  { monstreBK: "monstreBK_06" },
  { monstreBK: "monstreBK_07" },
  { monstreBK: "monstreBK_08" },
  { monstreBK: "monstreBK_09" }
]

const TabBKBKVille0 = [
  { villeBK: "villeBK_01" },
  { villeBK: "villeBK_02" },
  { villeBK: "villeBK_03" },
  { villeBK: "villeBK_04" },
  { villeBK: "villeBK_05" },
  { villeBK: "villeBK_06" },
  { villeBK: "villeBK_07" },
  { villeBK: "villeBK_08" },
  { villeBK: "villeBK_09" }
]

const TabBKBKMonstre1 = TabBKBKMonstre0.map(value => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value)
  .slice(0, 2)

const TabBKBKVille1 = TabBKBKVille0.map(value => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value)
  .slice(0, 7)

const addId = (arr: []) => {
  return arr.map(function (obj, index) {
    return Object.assign({}, obj, { idd: index });
  });
};
const TabBKBKMonstre2 = addId(TabBKBKMonstre1);
const TabBKBKVille2 = addId(TabBKBKVille1);

let tabScoreBK = [{ couleur: "rouge", score: 0 }, { couleur: "bleu", score: 0 }]

const ModuleBatailleKaiju: FunctionComponent<Props> = () => {

  let decpt: number = Math.floor(Math.random() * 3);

  const [CCSeltPR, setCCSeltPR] = useState("block")
  const [CCSeltFR, setCCSeltFR] = useState("block")
  const [CCSeltCR, setCCSeltCR] = useState("block")
  const [CSSBtBK, setCSSBtBK] = useState("none")
  const [CSSBtBKr, setCSSBtBKr] = useState("none")
  const [CSSBtBKf, setCSSBtBKf] = useState("none")
  const [msgBKr, setmsgBKr] = useState(".")
  const [scoreBK, setScoreBK] = useState(``)
  const [CCSMvtBKMonstreR, setCCSMvtBKMonstreR] = useState("imgBK_monstreR")
  const [CCSMvtBKMonstreB, setCCSMvtBKMonstreB] = useState("imgBK_monstreB")
  const [tagActBK,setTagActBK ] = useState<boolean>(true)
  //`${TabBKBKVille2[0].villeBK} imgBK_over01`
  const [tabCCSeltBBK, settabCCSeltBBK] = useState([
    { id: 0, CSSStyleEltBBK: "none", name: "P" },
    { id: 1, CSSStyleEltBBK: "none", name: "F" },
    { id: 2, CSSStyleEltBBK: "none", name: "C" }
  ])

  const updatetabEltBK = (Id: number, CSSUpdate: string) => {
    settabCCSeltBBK(tabCCSeltBBK.map(tab =>
      (tab.id === Id ? { ...tab, CSSStyleEltBBK: CSSUpdate } : tab)
    ));
  };

  const updateScoreBK = (Couleur: string) => {
    const newtab = tabScoreBK.map(tab =>
      tab = (tab.couleur == Couleur ? { ...tab, score: (tab.score + 1) } : tab)
    )
    tabScoreBK = newtab
  };

  const initMancheBK = () => {
    setCCSeltPR("block")
    setCCSeltFR("block")
    setCCSeltCR("block")
    settabCCSeltBBK([
      { id: 0, CSSStyleEltBBK: "none", name: "P" },
      { id: 1, CSSStyleEltBBK: "none", name: "F" },
      { id: 2, CSSStyleEltBBK: "none", name: "C" }
    ])
    setCSSBtBK("none")
    setCSSBtBKr("none")
    setCSSBtBKf("none")
  }

  const actBKPR = () => {
    if(tagActBK == true){
    setCCSeltCR("none");
    setCCSeltFR("none");
    playBK(15, 20, "P");
  }
}

  const actBKFR = () => {
    if(tagActBK == true){
    setCCSeltPR("none");
    setCCSeltCR("none");
    playBK(15, 20, "F");
  }
  }

  const actBKCR = () => {
    if(tagActBK == true){
    setCCSeltPR("none");
    setCCSeltFR("none");
    playBK(15, 20, "C");
  }
  }

  const loopDecptBK = () => {
    if (decpt < 2) {
      decpt = decpt + 1;
    }
    else {
      decpt = 0;
    }
    return decpt;
  }

  const playBK = (compteur: number, tempo: number, choixR: string, choixB?: string) => {
    choixR = choixR
    choixB = choixB
    setTagActBK(false)
    if (compteur < 1) {

      switch (choixR + "|" + choixB) {
        case ("P|P"): setmsgBKr("Egalité"); break;
        case ("P|F"): setmsgBKr("Perdu!"); updateScoreBK("bleu"); break;
        case ("P|C"): setmsgBKr("Gagné!"); updateScoreBK("rouge"); break;
        case ("F|P"): setmsgBKr("Gagné!"); updateScoreBK("rouge"); break;
        case ("F|F"): setmsgBKr("Egalité"); break;
        case ("F|C"): setmsgBKr("Perdu!"); updateScoreBK("bleu"); break;
        case ("C|P"): setmsgBKr("Perdu!"); updateScoreBK("bleu"); break;
        case ("C|F"): setmsgBKr("Gagné!"); updateScoreBK("rouge"); break;
        case ("C|C"): setmsgBKr("Egalité"); break;
      }
      setScoreBK(`Rouge : ${tabScoreBK.find(({ couleur }) => couleur == "rouge").score} / Bleu : ${tabScoreBK.find(({ couleur }) => couleur == "bleu").score}`)
      if (tabScoreBK.find((a) => a.couleur == "bleu").score == 3) {
        setmsgBKr("Partie perdue!");
        setCCSMvtBKMonstreB("imgBK_monstreB3")
        setTimeout(() => {
          setCSSBtBK("block");
          setCSSBtBKf("block");
        }, 2500)
        setTagActBK(true)
      }
      else if (tabScoreBK.find((a) => a.couleur == "rouge").score == 3) {
        setmsgBKr("Partie gagnée!");
        setCCSMvtBKMonstreR("imgBK_monstreR3")
        setTimeout(() => {
          setCSSBtBK("block");
          setCSSBtBKf("block");
        }, 2500)
        setTagActBK(true)
      }
      else {
        if (tabScoreBK.find((a) => a.couleur == "bleu").score == 1){ setCCSMvtBKMonstreB("imgBK_monstreB1") }
        if (tabScoreBK.find((a) => a.couleur == "bleu").score == 2){ setCCSMvtBKMonstreB("imgBK_monstreB2") }
        if (tabScoreBK.find((a) => a.couleur == "rouge").score == 1){ setCCSMvtBKMonstreR("imgBK_monstreR1") }
        if (tabScoreBK.find((a) => a.couleur == "rouge").score == 2){ setCCSMvtBKMonstreR("imgBK_monstreR2") }
        console.log(">>", tabScoreBK)
        setTimeout(() => {
          setCSSBtBK("block");
          setCSSBtBKr("block");
        }, 1500)
        setTagActBK(true)
      }
    } else {
      return setTimeout(() => {
        const idB = loopDecptBK()
        updatetabEltBK(idB, "block")
        choixB = tabCCSeltBBK.find((a) => a.id === idB).name
        playBK((compteur - 1), (tempo + (100 - (5 * compteur))), choixR, choixB);
      }, tempo);
    }
  }


  const continueBK = () => {
    initMancheBK()
  }

  const rejouerBK = () => {
    initMancheBK()
    setCCSMvtBKMonstreR("imgBK_monstreR");
    setCCSMvtBKMonstreB("imgBK_monstreB");
    tabScoreBK = [{ couleur: "rouge", score: 0 }, { couleur: "bleu", score: 0 }];
  }

  return <>
    <div className="cadreBK" style={{ width: "100%" }}>
      <div className="cadreBK_pl"></div>
      <img className="imgFondBK" src={fondVide} />
      <div className="cadreBK9">
        <div className="cadreBK99" style={{backgroundColor:"dark"}}>
          <div className="cadreBK999">
            <div className={`${TabBKBKVille2[0].villeBK} imgBK_over01`}>
              <img className="imgvideBKa" src={Carre00} />
            </div>
            <div className={`${TabBKBKVille2[1].villeBK} imgBK_over02`}>
              <img className="imgvideBKa" src={Carre00} />
            </div>
            <div className={`${TabBKBKVille2[2].villeBK}  imgBK_over03`}>
              <img className="imgvideBKa" src={Carre00} />
            </div>
          </div>
          <div className="cadreBK999">
            <div className={`${TabBKBKMonstre2[0].monstreBK} ${CCSMvtBKMonstreR}`}>
              <img className="imgvideBKa" src={Carre00} />
            </div>
          </div>
        </div>
        <div className="cadreBK99Mi">
          <div className="cadreBK99MiChoix">
            <div className="pierreBKR eltBKR" onClick={actBKPR} style={{ display: CCSeltPR }}>
              <img className="imgvideBKa" src={Carre00} />
            </div>
            <div className="feuilleBKR eltBKR" onClick={actBKFR} style={{ display: CCSeltFR }}>
              <img className="imgvideBKa" src={Carre00} />
            </div>
            <div className="ciseauxBKR eltBKR" onClick={actBKCR} style={{ display: CCSeltCR }}>
              <img className="imgvideBKa" src={Carre00} />
            </div>
            <div className="pierreBKB eltBKR" style={{ display: tabCCSeltBBK.find(({ id }) => id === 0).CSSStyleEltBBK }}>
              <img className="imgvideBKa" src={Carre00} />
            </div>
            <div className="feuilleBKB eltBKR" style={{ display: tabCCSeltBBK.find(({ id }) => id === 1).CSSStyleEltBBK }}>
              <img className="imgvideBKa" src={Carre00} />
            </div>
            <div className="ciseauxBKB eltBKR" style={{ display: tabCCSeltBBK.find(({ id }) => id === 2).CSSStyleEltBBK }}>
              <img className="imgvideBKa" src={Carre00} />
            </div>
          </div>
        </div>
        <div className="cadreBK99" style={{backgroundColor:"dark"}}>
          <div className="cadreBK999">
            <div className={`${TabBKBKVille2[3].villeBK} imgBK_over04`}>
              <img className="imgvideBKa" src={Carre00} />
            </div>
            <div className={`${TabBKBKVille2[4].villeBK} imgBK_over05`}>
              <img className="imgvideBKa" src={Carre00} />
            </div>
            <div className={`${TabBKBKVille2[5].villeBK}  imgBK_over06`}>
              <img className="imgvideBKa" src={Carre00} />
            </div>
          </div>
          <div className="cadreBK999">
            <div className={`${TabBKBKMonstre2[1].monstreBK}  ${CCSMvtBKMonstreB}`}>
              <img className="imgvideBKa" src={Carre00} />
            </div>
          </div>
        </div>
      </div>
      <div className="cadreBK_msg" style={{ display: CSSBtBK }}>
        <div className="cadreBK7_msg">
          <div className="cadreBK77_msg">
            <h2 className="winner000"><span>{scoreBK}<br/>{msgBKr}</span></h2>
            <button className="reponse000" style={{ display: CSSBtBKr }} onClick={continueBK}>Continuer?</button>
            <button className="reponse000" style={{ display: CSSBtBKf }} onClick={rejouerBK}>Rejouer?</button>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default ModuleBatailleKaiju;
