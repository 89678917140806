import React, {useEffect, useState } from 'react';

const DelaiAff = (a:number) => {
    const [chrgt00, serChrgt00] = useState("none");
  
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        serChrgt00("block")
      }, a);
      return () => clearTimeout(timeoutId);
    }, []);
    return chrgt00
  }

export default DelaiAff;
