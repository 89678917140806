import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import useDraggableX from '../helpers/drag_elt_x';
import fondVide from "../assets/img/img_space/FondVide800x720.gif"
import './module_ile_yokai0.css';
type Props = {
};



const ModuleIleYokai0: FunctionComponent<Props> = () => {

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);

    const [CCSlgIY, setCCSlgIY] = useState<string>("50%")
    const [CCSlgIYb, setCCSlgIYb] = useState<string>("44%")
    const [CCSCadreIY, setCCSCadreIY] = useState<string>("cadreIY_v1")
    const [CCSTagIY, setCCSTagIY] = useState<boolean>(true)
    const [widthPctIY, setwidthPctIY] = useState(50)
    const [CCSTempoIY, setCCSTempoIY] = useState<boolean>(true)


    const actIY = (event) => {

        const eltCadreIY = ReactDOM.findDOMNode(ref1.current);
        const lgCadreIY = eltCadreIY.getBoundingClientRect().width;
        const XCadreIY = eltCadreIY.getBoundingClientRect().x;
        const OnePctIY = lgCadreIY / 100;
        //console.log((event.clientX)-(XCadreIY))
        setwidthPctIY(((event.clientX) - (XCadreIY)) / OnePctIY)
            setCCSlgIY(`${(widthPctIY > 0 ? (widthPctIY < 100 ? widthPctIY : 100) : 0)}%`)
            setCCSlgIYb(`${(widthPctIY > 0 ? (widthPctIY < 100 ? widthPctIY : 100) : 0) - 6}%`)
            if (widthPctIY > 95 && CCSTempoIY == true) {
                if (CCSTagIY == true) {               
                        setCCSCadreIY("cadreIY_v2");
                        setCCSTagIY(false)
                        setCCSTempoIY(false)
                }
                else {                   
                        setCCSCadreIY("cadreIY_v1");
                        setCCSTagIY(true)
                        setCCSTempoIY(false)
                }
                setTimeout(()=>{ setCCSTempoIY(true)},1000);
            }
        }
    
        
        const chgIY = () => {
            if (CCSTagIY == true) {               
                setCCSCadreIY("cadreIY_v2");
                setCCSTagIY(false)
                setCCSTempoIY(false)
        }
        else {                   
                setCCSCadreIY("cadreIY_v1");
                setCCSTagIY(true)
                setCCSTempoIY(false)
        }
        }
        
    return <>

        <div className={CCSCadreIY} style={{ width: "100%" }} ref={ref1} onMouseMove={actIY} onClick={actIY}>
            <div className='idAncre00'></div>
            <div className="cadreIY_pl"></div>
            <img className="imgFondIY" src={fondVide} />
            <div className="cadreIY9" ref={ref2} style={{ width: CCSlgIY }} ></div>
            <div className="cadreIY9b" ref={ref3} style={{ left: CCSlgIYb }} onMouseMove={actIY} onClick={chgIY} ></div>
        </div>


    </>
}
export default ModuleIleYokai0;