import React, { FunctionComponent, useState } from 'react';
import Carre00 from "../assets/img/img_space/FondVide800x720.gif";
import MemoryFondElts003_back from "../assets/img/img_space/MemoryFondElts003_back.webp";
import FondVid from "../assets/img/img_space/FondKheopVid.gif"

import MemoryFondElts003_01 from "../assets/img/img_space/MemoryFondElts003_01.webp";
import MemoryFondElts003_02 from "../assets/img/img_space/MemoryFondElts003_02.webp";
import MemoryFondElts003_03 from "../assets/img/img_space/MemoryFondElts003_03.webp";
import MemoryFondElts003_04 from "../assets/img/img_space/MemoryFondElts003_04.webp";
import MemoryFondElts003_05 from "../assets/img/img_space/MemoryFondElts003_05.webp";
import MemoryFondElts003_06 from "../assets/img/img_space/MemoryFondElts003_06.webp";
import MemoryFondElts003_07 from "../assets/img/img_space/MemoryFondElts003_07.webp";
import MemoryFondElts003_08 from "../assets/img/img_space/MemoryFondElts003_08.webp";

import './module_memory_noir.css';

type Props = {
};

let matchMNPremier: number = 0;
let matchMNSecond: number = 0;
let matchMNCpt: number = 0;

const ModuleMemoryNoir: FunctionComponent<Props> = () => {

  const tabEltsMN0 = [
    { id: 1, name: "Iris", imgScr: MemoryFondElts003_01, CCSPersoMN01F01: "frontMN1 frontMN1_perso01", CCSPersoMN01F02: "frontMN2 frontMN2_perso01", CCSCard: "card", CCSinsideMN: "insideMN" },
    { id: 2, name: "Diana", imgScr: MemoryFondElts003_02, CCSPersoMN01F01: "frontMN1 frontMN1_perso02", CCSPersoMN01F02: "frontMN2 frontMN2_perso02", CCSCard: "card", CCSinsideMN: "insideMN" },
    { id: 3, name: "Ingrid", imgScr: MemoryFondElts003_03, CCSPersoMN01F01: "frontMN1 frontMN1_perso03", CCSPersoMN01F02: "frontMN2 frontMN2_perso03", CCSCard: "card", CCSinsideMN: "insideMN" },
    { id: 4, name: "Barbara", imgScr: MemoryFondElts003_04, CCSPersoMN01F01: "frontMN1 frontMN1_perso04", CCSPersoMN01F02: "frontMN2 frontMN2_perso04", CCSCard: "card", CCSinsideMN: "insideMN" },
    { id: 5, name: "Bob", imgScr: MemoryFondElts003_05, CCSPersoMN01F01: "frontMN1 frontMN1_perso05", CCSPersoMN01F02: "frontMN2 frontMN2_perso05", CCSCard: "card", CCSinsideMN: "insideMN" },
    { id: 6, name: "Brutus", imgScr: MemoryFondElts003_06, CCSPersoMN01F01: "frontMN1 frontMN1_perso06", CCSPersoMN01F02: "frontMN2 frontMN2_perso06", CCSCard: "card", CCSinsideMN: "insideMN" },
    { id: 7, name: "Louis", imgScr: MemoryFondElts003_07, CCSPersoMN01F01: "frontMN1 frontMN1_perso07", CCSPersoMN01F02: "frontMN2 frontMN2_perso07", CCSCard: "card", CCSinsideMN: "insideMN" },
    { id: 8, name: "Robin", imgScr: MemoryFondElts003_08, CCSPersoMN01F01: "frontMN1 frontMN1_perso08", CCSPersoMN01F02: "frontMN2 frontMN2_perso08", CCSCard: "card", CCSinsideMN: "insideMN" }
  ]
  //six premieres valeurs au hazard
  const tabEltsMN = () => {
    const tabEltsMN1 = tabEltsMN0.map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
      .slice(0, 6)
    //ajout du tableau a lui meme
    const tabEltsMN2 = [...tabEltsMN1, ...tabEltsMN1]
    //melange des valeurs
    const tabEltsMN3 = tabEltsMN2.map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
    //ajout d'idd unique
    const addId = (arr: []) => {
      return arr.map(function (obj, index) {
        return Object.assign({}, obj, { idd: index + 1 });
      });
    };
    const tabEltsMN4 = addId(tabEltsMN3);

    return tabEltsMN4
  }

  const [tabEltsMN5, SettabEltsMN5] = useState(tabEltsMN())

  const [CSSvictoireMN, setCSSvictoireMN] = useState<string>("none")

  //ajout d'un elt dans un tableau
  //<button onClick={() => {tab.push({ id: nextId++,name: name,});}}>Add</button
  //ajout d'un elt dans un tableau dans un usestate
  //setTab([ ...Tab,{ id: nextId++, name: name }]

  const modiftabEltsMN5 = (idd: number, visible2727: boolean) => {
    const newtabEltsMN5 = [...tabEltsMN5];
    const CCSModifMN = newtabEltsMN5.find(
      a => a.idd == idd
    );
    if (visible2727 == true) { CCSModifMN.CCSinsideMN = "insideMN pickedMN"; }
    else { CCSModifMN.CCSinsideMN = "insideMN"; }

    SettabEltsMN5(newtabEltsMN5);
  }

  const matchMN = (e: React.ChangeEvent<HTMLInputElement>) => {
    const iddSearch = e.currentTarget.getAttribute("data-idd");
    if (matchMNSecond == 0) {
      modiftabEltsMN5(iddSearch, true);

      if (matchMNPremier == 0) {
        matchMNPremier = iddSearch;
      }
      else {
        matchMNSecond = iddSearch;
        setTimeout(() => {
          const premierEltMN = tabEltsMN5.find(a => a.idd == matchMNPremier);
          const secondEltMN = tabEltsMN5.find(a => a.idd == iddSearch);
          if (premierEltMN.id == secondEltMN.id) {
            modiftabEltsMN5(matchMNPremier, true);
            modiftabEltsMN5(iddSearch, true);
            matchMNCpt = matchMNCpt + 1;
            matchMNPremier = 0;
            matchMNSecond = 0;
            if (matchMNCpt == 6) {
              setCSSvictoireMN("block")
            }
          }
          else {
            modiftabEltsMN5(matchMNPremier, false);
            modiftabEltsMN5(iddSearch, false);
            matchMNPremier = 0;
            matchMNSecond = 0;
          }
        }, 1000)
      }
    }
  }

  const restartMN = () => {
    SettabEltsMN5(tabEltsMN());
    setCSSvictoireMN("none")
  }

  return <>
      <div className="cadreMN" style={{ width: "100%" }}>
        <div className="cadreMN_pl"></div>
        <img className="imgFondMN" src={Carre00} />
        <div className="cadreMN7">
          {tabEltsMN5.map((elt) => (
            <div className={elt.CCSCard} key={elt.idd} data-id={elt.id} data-idd={elt.idd} onClick={e => matchMN(e)}>
              <div className={elt.CCSinsideMN}>
                <div className="frontMN">
                  <img src={elt.imgScr} />
                  <div className={elt.CCSPersoMN01F01}>
                  </div>
                  <div className={elt.CCSPersoMN01F02}>
                  </div>
                </div>
                <div className="backMN">
                  <img src={MemoryFondElts003_back} />
                </div>
              </div>
            </div>
          ))}
          <div className="cadreMN_msg" style={{ display: CSSvictoireMN }}>
            <div className="cadreMN7_msg">
              <div className="cadreMN77_msg">
                <h2 className="winner000"><span>Bravo!</span></h2>
                <button className="reponse000" onClick={restartMN}>Rejouer?</button>
              </div>
            </div>
          </div>
          {/*<div className="cadreMN7_titre">
            <img className="imgFondMN" src={FondVid} />
          </div>*/}
        </div>
      </div>
  </>
}

export default ModuleMemoryNoir;