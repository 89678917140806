import React, { FunctionComponent, useState, useEffect } from 'react';

import './module_parallaxe.css';

type Props = {
};

const ModuleParallaxe: FunctionComponent<Props> = () => {

    return<>
    <div className="parallax-world-of-ugg">
  <div className="prllx parallax-one">
  </div>
  <div className="prllx parallax-two">
  </div>
  <div className="prllx parallax-one">
  </div>
  <div className="prllx parallax-two">
  </div>
  <div className="prllx parallax-one">
  </div>
  <div className="prllx parallax-two">
  </div>
  <div className="prllx parallax-one">
  </div>
  <div className="prllx parallax-two">
  </div>
  <div className="prllx parallax-one">
  </div>
  <div className="prllx parallax-two">
  </div>
  <div className="prllx parallax-one">
  </div>
  <div className="prllx parallax-two">
  </div>
  <div className="prllx parallax-one">
  </div>
  <div className="prllx parallax-two">
  </div>
</div>
</>
}

export default ModuleParallaxe;
