import React, { Component, FunctionComponent, useEffect, useState, Suspense } from 'react';

import ModuleFlip from '../components/module_flip';
import ModuleTxtAccueil from '../components/module_txt_accueil';
import ModuleTxtFooter from '../components/module_txt_footer';
import ModuleBatailleKaiju from '../components/module_bataille_kaiju';

import CadreCarre from './cadre_carre';
import ModuleRoiMages from '../components/module_rois_mages';
import ModuleMap from '../components/module_map';
import ModuleIleYokai0 from '../components/module_ile_yokai0';
import ModuleSansIssue from '../components/module_sans_issue';
import ModuleDessin from '../components/module_dessin';

type Props = {
  tab:[]
};
  
  const PageN1: FunctionComponent<Props> = ({tab}) => {

    let delai:number = 200;
    const delaiDecpt = () =>{
      delai = delai + 100;
      return delai;
    }

//<ModuleResize/>
//<CadreCarre delai={100}><ModuleResize/></CadreCarre>
//<CadreCarre delai={100}><ModuleBatailleKaiju/></CadreCarre>
// <CadreCarre delai={100}><ModuleMap/></CadreCarre>
//<CadreCarre delai={100}><ModuleIleYokai0/></CadreCarre>
/*
<CadreCarre delai={100}><ModuleSansIssue/></CadreCarre>
       <CadreCarre delai={100}><ModuleDessin/></CadreCarre>
      <CadreCarre delai={100}><ModuleMap/></CadreCarre>
      */
  return(
       <>

      <ModuleTxtAccueil/> 
       <ModuleFlip/>
       
          {tab.map((a) => (
            <CadreCarre key={a.index}  idd={a.ancre} delai={delaiDecpt()}>{a.module}</CadreCarre>
          ))}

          <ModuleTxtFooter/> 
      </>
  )
  
  
  }


  //
  export default PageN1;