import React, { FunctionComponent, useState, useEffect } from 'react';
import Dessin from '../models/dessin-s';
import CadreFlip from './cadre_flip';
import DESSINS_S from '../models/dessin-s-list';

type Props = {
};

const ModuleFlip: FunctionComponent<Props> = () => {

    const [dessinsFF0, setDessinFF] = useState<Dessin[]>([]);

    useEffect(() => {
        setDessinFF(DESSINS_S);
      },[])

      const dessinsFF1 = dessinsFF0.map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
      .slice(0, 24)

      const addId = (arr: []) => {
        return arr.map(function (obj, index) {
          return Object.assign({}, obj, { idd: index + 1 });
        });
      };

let _switch = true;
let idd = 0;

const dessinsFF2 = []
let imgScr0 = ""
let imgTxt0 = ""
let imgGrp0 = ""

dessinsFF1.map(a => {
    if (_switch) {imgScr0= a.imgScr; imgTxt0 = a.imgTxt; imgGrp0 = a.imgGrp }
    else {dessinsFF2.push({
        id:idd,
        imgScr1:imgScr0,
        imgTxt1:imgTxt0,
        imgGrp1:imgGrp0,
        imgScr2:a.imgScr,
        imgTxt2:a.imgTxt,
        imgGrp2:a.imgGrp }
    );idd=idd+1};
    _switch = !_switch;
})

 return<>
           {dessinsFF2.map((dessinFF,index) => (
            <CadreFlip key={index} dessinFF={dessinFF}/>
          ))}
 </>
}

export default ModuleFlip;
