import React, { FunctionComponent, useState } from 'react';
import FinDuMonde04_fond from "../assets/img/img_space/FinDuMonde04_fond.webp"
import './module_fin_monde.css';

type Props = {
};

const tabEltsFM =
  ["cadreanim00 super_cadreFM cadre_a",
    "cadreanim00 super_cadreFM cadre_b",
    "cadreanim00 super_cadreFM cadre_c",
    "cadreanim00 super_cadreFM cadre_d"]

const ModuleFinMonde: FunctionComponent<Props> = () => {

  let decpt: number = Math.floor(Math.random() * 11);

  const [CSSBtFM, setCSSBtFM] = useState<string>("block");
  const [CSSBtFMr, setCSSBtFMr] = useState<string>("none");

  const [tabNoFM, setTabNoFM] = useState([
    { id: 1, CSSStyleFM: "none" },
    { id: 2, CSSStyleFM: "none" },
    { id: 3, CSSStyleFM: "none" },
    { id: 4, CSSStyleFM: "none" },
    { id: 5, CSSStyleFM: "none" },
    { id: 6, CSSStyleFM: "none" },
    { id: 7, CSSStyleFM: "none" },
    { id: 8, CSSStyleFM: "none" },
    { id: 9, CSSStyleFM: "none" },
    { id: 10, CSSStyleFM: "none" }
  ])

  const tabNoFMInit = [
    { id: 1, CSSStyleFM: "none" },
    { id: 2, CSSStyleFM: "none" },
    { id: 3, CSSStyleFM: "none" },
    { id: 4, CSSStyleFM: "none" },
    { id: 5, CSSStyleFM: "none" },
    { id: 6, CSSStyleFM: "none" },
    { id: 7, CSSStyleFM: "none" },
    { id: 8, CSSStyleFM: "none" },
    { id: 9, CSSStyleFM: "none" },
    { id: 10, CSSStyleFM: "none" }
  ]

  const updatetabNoFM = (Id: number, CSSUpdate: string) => {
    setTabNoFM(tabNoFM.map(tab =>
      tab.id === Id ? { ...tab, CSSStyleFM: CSSUpdate } : tab
    ));
  };

  const loopDecptFM = () => {
    if (decpt < 10) {
      decpt = decpt + 1;
    }
    else {
      decpt = 1;
    }
    return decpt;
  }

  const reponseFM = () => {
    playFM(20, 20);
    setCSSBtFM("none");
  }

  const playFM = (compteur: number, tempo: number) => {
    if (compteur < 1) {
      setCSSBtFMr("block");
    } else {
      return setTimeout(() => { playFM((compteur - 1), (tempo + (100 - (5 * compteur)))); updatetabNoFM(loopDecptFM(), "block") }, tempo);
    }
  }

  const restartFM = () => {
    setTabNoFM(tabNoFMInit);
    setCSSBtFMr("none");
    setCSSBtFM("block");
  }

  return <>
      <div className="cadreFM" style={{ width: "100%" }}>
        <div className="cadreFM_pl">{decpt}</div>
        <img className="imgFondFM" src={FinDuMonde04_fond} />
        <div className="cadreFM1 noFM1" style={{ display: tabNoFM.find(({ id }) => id === 1).CSSStyleFM }}><img className="imgvideFMa" /></div>
        <div className="cadreFM1 noFM2" style={{ display: tabNoFM.find(({ id }) => id === 2).CSSStyleFM }}><img className="imgvideFMa" /></div>
        <div className="cadreFM1 noFM3" style={{ display: tabNoFM.find(({ id }) => id === 3).CSSStyleFM }}><img className="imgvideFMa" /></div>
        <div className="cadreFM1 noFM4" style={{ display: tabNoFM.find(({ id }) => id === 4).CSSStyleFM }}><img className="imgvideFMa" /></div>
        <div className="cadreFM1 noFM5" style={{ display: tabNoFM.find(({ id }) => id === 5).CSSStyleFM }}><img className="imgvideFMa" /></div>
        <div className="cadreFM1 noFM6" style={{ display: tabNoFM.find(({ id }) => id === 6).CSSStyleFM }}><img className="imgvideFMa" /></div>
        <div className="cadreFM1 noFM7" style={{ display: tabNoFM.find(({ id }) => id === 7).CSSStyleFM }}><img className="imgvideFMa" /></div>
        <div className="cadreFM1 noFM8" style={{ display: tabNoFM.find(({ id }) => id === 8).CSSStyleFM }}><img className="imgvideFMa" /></div>
        <div className="cadreFM1 noFM9" style={{ display: tabNoFM.find(({ id }) => id === 9).CSSStyleFM }}><img className="imgvideFMa" /></div>
        <div className="cadreFM1 noFM10" style={{ display: tabNoFM.find(({ id }) => id === 10).CSSStyleFM }}><img className="imgvideFMa" /></div>
        <div className="cadreFM_ca"></div>
        <div className="cadreFM1b" style={{ display: CSSBtFM }} onClick={reponseFM}>
          <div className="cadreFM3_msg">
            <div className="cadreFM33_msg">
              <h2 className="winner000"><span>Voulez connaitre la date de la Fin du Monde?</span></h2>
              <button className="reponse000">OUI</button>
            </div>
          </div>
        </div>
        <div className="cadreFM1b" style={{ display: CSSBtFMr }} onClick={restartFM}>
          <div className="cadreFM3_msg">
            <div className="cadreFM33_msg">
              <h2 id="winPrizes" className="winner000">
                <span className="winner000" style={{ display: tabNoFM.find(({ id }) => id === 1).CSSStyleFM }}>Ce n'est qu'un mythe! La Fin du Monde n'existe pas ! Les choses se répètent, sans fin, indéfiniment, encore et encore! </span>
                <span className="winner000" style={{ display: tabNoFM.find(({ id }) => id === 2).CSSStyleFM }}>D'après les lois de l'obsolescence quantique, la Fin du Monde est programmée dans 31 557 600 581 055 967 secondes! </span>
                <span className="winner000" style={{ display: tabNoFM.find(({ id }) => id === 3).CSSStyleFM }}>La planète se meurt. Elle est malade. Les petits oiseaux disparaissent! La Fin du Monde a déjà commencé!</span>
                <span className="winner000" style={{ display: tabNoFM.find(({ id }) => id === 4).CSSStyleFM }}>N'y pensons plus. La Fin du Monde est inconnue. Elle peut advenir demain comme dans mille ans! A quoi bon s'en soucier. </span>
                <span className="winner000" style={{ display: tabNoFM.find(({ id }) => id === 5).CSSStyleFM }}>Ouvrez votre meilleur whisky car la Fin du Monde, c'est demain! Aujourd'hui le Soleil s' est levé pour la dernière fois!</span>
                <span className="winner000" style={{ display: tabNoFM.find(({ id }) => id === 6).CSSStyleFM }}>La Fin du Monde est très approximative! Il y a beaucoup de paramètres incertains mais tous nos experts planchent sur la question.</span>
                <span className="winner000" style={{ display: tabNoFM.find(({ id }) => id === 7).CSSStyleFM }}>La Fin du Monde est prévue le 24 octobre 3654 à 20h58 33 minutes et 25 secondes! Mais c'est le problème de nos arrières petits enfants!</span>
                <span className="winner000" style={{ display: tabNoFM.find(({ id }) => id === 8).CSSStyleFM }}>La Fin du Monde adviendra, dans environ 1 milliard d'années à la mort de notre étoile, le Soleil, qui s'embrasera et détruira tout.</span>
                <span className="winner000" style={{ display: tabNoFM.find(({ id }) => id === 9).CSSStyleFM }}>Soyons clair!  Est ce vraiment le début de la fin ou plutôt simplement la fin du début?</span>
                <span className="winner000" style={{ display: tabNoFM.find(({ id }) => id === 10).CSSStyleFM }}>La Fin du Monde est très attendue ! Des montagnes de chocolat et des océans de rhum nous attendent au Paradis!</span>
              </h2>
              <button className="restart000" >Autre Réponse?</button>
            </div>
          </div>
        </div>
      </div>
  </>
}

export default ModuleFinMonde;
