import React, { FunctionComponent, useState, useEffect, useRef, useMemo } from 'react';

import './cadre_flip.css';
type Props = {
  dessinFF: Dessin
}

type Dessin = {
  id: number,
  imgScr1: string,
  imgTxt1: string,
  imgGrp1: string,
  imgScr2: string,
  imgTxt2: string,
  imgGrp2: string
};

const CadreFlip: FunctionComponent<Props> = ({ dessinFF }) => {

const [flipFFF, setFlipFFF] = useState<string>("cadreFF");
  const [flipFFB, setFlipFFB] = useState<boolean>(true);

  const flipFF = () => {
    if (flipFFB === true) {
      setFlipFFF("cadreFF flippedFF");
      setFlipFFB(false);
    } else {
      setFlipFFF("cadreFF");
      setFlipFFB(true);
    }
  }

  const tabStyleFF = [
    "rect00 super_cadreFF_a cadre_a",
    "rect00 super_cadreFF_b cadre_b",
    "rect00 super_cadreFF_c cadre_c",
    "rect00 super_cadreFF_d cadre_d"
  ]

  const tabCadreFF = [
    "rect00 super_cadreFF_a cadre_a",
    "rect00 super_cadreFF_b cadre_b",
    "rect00 super_cadreFF_c cadre_c",
    "rect00 super_cadreFF_d cadre_d"
  ]

  
  
  
  const LeftRightCSSRandomFF = tabStyleFF[Math.floor(Math.random() * tabStyleFF.length)]


  const ref = useRef<HTMLDivElement>(null)

  const useOnScreen = (ref: RefObject<HTMLElement>) => {

      const [isIntersecting, setIntersecting] = useState(false)
    
      const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
      ), [ref])
    
    
      useEffect(() => {
        observer.observe(ref.current)
        return () => observer.disconnect()
      }, [])
    
      return isIntersecting
    }

const isVisible = useOnScreen(ref)

  return (
    <>
      <div  ref={ref} onContextMenu={(e)=>  {e.preventDefault(); return false;}} className={LeftRightCSSRandomFF}>
        <div className={flipFFF} onClick={flipFF}>
          <div className="front_FF">
            <img src={dessinFF.imgScr1} alt={dessinFF.imgTxt1} />
            <div className="txtFF_d"><h2 className="txtFF_h2"><span>{dessinFF.imgTxt1}</span></h2></div>
          </div>
          <div className="back_FF">
            <img src={dessinFF.imgScr2} alt={dessinFF.imgTxt2} />
            <div className="txtFF_d"><h2 className="txtFF_h2"><span>{dessinFF.imgTxt2}</span></h2></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CadreFlip;

