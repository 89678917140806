import React, { FunctionComponent, useState } from 'react';
import FondFujiSanVid  from "../assets/img/img_space/FondFujiSanVid.gif";
import PiafGrueJapMvtFond  from "../assets/img/img_space/PiafGrueJapMvtFond.gif"
import BoutonFS01_vide from "../assets/img/img_space/BoutonFS01_vide.gif"
import './module_fuji_san.css';

type Props = {
};

const ModuleFujiSan: FunctionComponent<Props> = () => {

  const [CSSFujiFS01, setCSSFujiFS01] = useState<string>("cadreFS5_fuji01actif");
  const [CSSFujiFS02, setCSSFujiFS02] = useState<string>("cadreFS5_fuji02droite");
  const [CSSFujiFS03, setCSSFujiFS03] = useState<string>("cadreFS5_fuji03droite");
  const [CSSFujiFS04, setCSSFujiFS04] = useState<string>("cadreFS5_fuji04droite");
  const [CSSFujiFS05, setCSSFujiFS05] = useState<string>("cadreFS5_fuji05droite");

  const [CSSBtFS01, setCSSBtFS01] = useState<string>("btFS btFS_active fuji01_b  btFS1");
  const [CSSBtFS02, setCSSBtFS02] = useState<string>("btFS btFS fuji02_b  btFS2d");
  const [CSSBtFS03, setCSSBtFS03] = useState<string>("btFS btFS fuji03_b  btFS3d");
  const [CSSBtFS04, setCSSBtFS04] = useState<string>("btFS btFS fuji04_b  btFS4d");
  const [CSSBtFS05, setCSSBtFS05] = useState<string>("btFS btFS fuji05_b  btFS5d");

  const [CSSgrueFS01, setCSSgrueFS01] = useState<string>("eltFS_grue01");
  const [CSSgrueFS02, setCSSgrueFS02] = useState<string>("eltFS_grue00");
  const [CSSgrueFS03, setCSSgrueFS03] = useState<string>("eltFS_grue00");
  const [CSSgrueFS04, setCSSgrueFS04] = useState<string>("eltFS_grue00");
  const [CSSgrueFS05, setCSSgrueFS05] = useState<string>("eltFS_grue00");

  const [CSSTxtFS01, setCSSTxtFS01] = useState<string>("txtFS_fs");
  const [CSSTxtFS02, setCSSTxtFS02] = useState<string>("txtFS_fs");
  const [CSSTxtFS03, setCSSTxtFS03] = useState<string>("txtFS_fs");
  const [CSSTxtFS04, setCSSTxtFS04] = useState<string>("txtFS_fs");
  const [CSSTxtFS05, setCSSTxtFS05] = useState<string>("txtFS_fs");

  const initCSSgrueFS = () => {
    setCSSgrueFS01("eltFS_grue00");
    setCSSgrueFS02("eltFS_grue00");
    setCSSgrueFS03("eltFS_grue00");
    setCSSgrueFS04("eltFS_grue00");
    setCSSgrueFS05("eltFS_grue00");
  }

  const initCSSTxtFS = () => {
    setCSSTxtFS01("txtFS_fs");
    setCSSTxtFS02("txtFS_fs");
    setCSSTxtFS03("txtFS_fs");
    setCSSTxtFS04("txtFS_fs");
    setCSSTxtFS05("txtFS_fs");
  }

  const ActFS_1 = () => {
    initCSSTxtFS();
    setCSSFujiFS01("cadreFS5_fuji01actif");
    setCSSFujiFS02("cadreFS5_fuji02droite");
    setCSSFujiFS03("cadreFS5_fuji03droite");
    setCSSFujiFS04("cadreFS5_fuji04droite");
    setCSSFujiFS05("cadreFS5_fuji05droite");

    setCSSBtFS01("btFS btFS_active fuji01_b btFS1");
    setCSSBtFS02("btFS btFS fuji02_b btFS2d");
    setCSSBtFS03("btFS btFS fuji03_b btFS3d");
    setCSSBtFS04("btFS btFS fuji04_b btFS4d");
    setCSSBtFS05("btFS btFS fuji05_b btFS5d");

    initCSSgrueFS();
    setCSSgrueFS01("eltFS_grue01");
    setCSSTxtFS01("txtFS_fs txtFS_fs_active");
    setTimeout(() => {setCSSTxtFS01("txtFS_fs")},5000);
  }

  const ActFS_2 = () => {
    initCSSTxtFS();
    setCSSFujiFS01("cadreFS5_fuji01gauche");
    setCSSFujiFS02("cadreFS5_fuji02actif");
    setCSSFujiFS03("cadreFS5_fuji03droite");
    setCSSFujiFS04("cadreFS5_fuji04droite");
    setCSSFujiFS05("cadreFS5_fuji05droite");

    setCSSBtFS01("btFS btFS fuji01_b btFS1");
    setCSSBtFS02("btFS btFS_active fuji02_b  btFS2g");
    setCSSBtFS03("btFS btFS fuji03_b btFS3d");
    setCSSBtFS04("btFS btFS fuji04_b  btFS4d");
    setCSSBtFS05("btFS btFS fuji05_b  btFS5d");

    initCSSgrueFS();
    setCSSgrueFS02("eltFS_grue02");
    setCSSTxtFS02("txtFS_fs txtFS_fs_active");
    setTimeout(() => {setCSSTxtFS02("txtFS_fs")},5000);
  }

  const ActFS_3 = () => {
    initCSSTxtFS();
    setCSSFujiFS01("cadreFS5_fuji01gauche");
    setCSSFujiFS02("cadreFS5_fuji02gauche");
    setCSSFujiFS03("cadreFS5_fuji03actif");
    setCSSFujiFS04("cadreFS5_fuji04droite");
    setCSSFujiFS05("cadreFS5_fuji05droite");

    setCSSBtFS01("btFS btFS fuji01_b btFS1");
    setCSSBtFS02("btFS btFS fuji02_b  btFS2g");
    setCSSBtFS03("btFS btFS_active fuji03_b   btFS3g");
    setCSSBtFS04("btFS btFS fuji04_b   btFS4d");
    setCSSBtFS05("btFS btFS fuji05_b  btFS5d");

    initCSSgrueFS();
    setCSSgrueFS03("eltFS_grue03");
    setCSSTxtFS03("txtFS_fs txtFS_fs_active");
    setTimeout(() => {setCSSTxtFS03("txtFS_fs")},5000);
  }

  const ActFS_4 = () => {
    initCSSTxtFS();
    setCSSFujiFS01("cadreFS5_fuji01gauche");
    setCSSFujiFS02("cadreFS5_fuji02gauche");
    setCSSFujiFS03("cadreFS5_fuji03gauche");
    setCSSFujiFS04("cadreFS5_fuji04actif");
    setCSSFujiFS05("cadreFS5_fuji05droite");

    setCSSBtFS01("btFS btFS fuji01_b btFS1");
    setCSSBtFS02("btFS btFS fuji02_b  btFS2g");
    setCSSBtFS03("btFS btFS fuji03_b btFS3g");
    setCSSBtFS04("btFS btFS_active fuji04_b   btFS4g");
    setCSSBtFS05("btFS btFS fuji05_b  btFS5d");

    initCSSgrueFS();
    setCSSgrueFS04("eltFS_grue04");
    setCSSTxtFS04("txtFS_fs txtFS_fs_active");
    setTimeout(() => {setCSSTxtFS04("txtFS_fs")},5000);
  }

  const ActFS_5 = () => {
    initCSSTxtFS();
    setCSSFujiFS01("cadreFS5_fuji01gauche");
    setCSSFujiFS02("cadreFS5_fuji02gauche");
    setCSSFujiFS03("cadreFS5_fuji03gauche");
    setCSSFujiFS04("cadreFS5_fuji04gauche");
    setCSSFujiFS05("cadreFS5_fuji05actif");

    setCSSBtFS01("btFS btFS fuji01_b btFS1");
    setCSSBtFS02("btFS btFS fuji02_b  btFS2g");
    setCSSBtFS03("btFS btFS fuji03_b  btFS3g");
    setCSSBtFS04("btFS btFS fuji04_b  btFS4g");
    setCSSBtFS05("btFS btFS_active fuji05_b  btFS5g");

    initCSSgrueFS();
    setCSSgrueFS05("eltFS_grue05");
    setCSSTxtFS05("txtFS_fs txtFS_fs_active");
    setTimeout(() => {setCSSTxtFS05("txtFS_fs")},5000);



  }

  return <>
      <div className="cadreFS" style={{ width: "100%" }}>
        <div className="cadreFS_pl"></div>
        <img className="imgFondFS" src={FondFujiSanVid} />
        <div id="fuji01" className={CSSFujiFS01}>
          <div id="txtFS_fs01" className={CSSTxtFS01}>
            <h2><span>Dans les jeunes herbes</span></h2>
            <h2><span>Le vieux saule</span></h2>
            <h2><span>Oublie ses racines.</span></h2>
          </div>
          <div id="cadreFS5_01" className="cadreFS5">
            <div className="eltFS_grue">
              <div className={CSSgrueFS01}>
                <div className="eltFS_grue_mvt0">
                  <img className="imgFondFS" src={PiafGrueJapMvtFond} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="fuji02" className={CSSFujiFS02}>
          <div id="txtFS_fs02" className={CSSTxtFS02}>
            <h2><span>Ce chemin -</span></h2>
            <h2><span>Seule la pénombre d'automne</span></h2>
            <h2><span>L'emprunte encore.</span></h2>
          </div>
          <div id="cadreFS5_02" className="cadreFS5" >
            <div className="eltFS_grue">
              <div className={CSSgrueFS02}>
                <div className="eltFS_grue_mvt0">
                  <img className="imgFondFS" src={PiafGrueJapMvtFond} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="fuji03" className={CSSFujiFS03}>
          <div id="txtFS_fs03" className={CSSTxtFS03} >
            <h2><span>Ce couchant d'automne</span></h2>
            <h2><span>On dirait</span></h2>
            <h2><span>Le Pays des ombres.</span></h2>
          </div>
          <div id="cadreFS5_03" className="cadreFS5" >
            <div className="eltFS_grue">
              <div className={CSSgrueFS03}>
                <div className="eltFS_grue_mvt0">
                  <img className="imgFondFS" src={PiafGrueJapMvtFond} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="fuji04" className={CSSFujiFS04}>
          <div id="txtFS_fs04" className={CSSTxtFS04} >
            <h2><span>Le printemps qui s’éloigne</span></h2>
            <h2><span>Hésite</span></h2>
            <h2><span>Parmi les derniers cerisiers.</span></h2>
          </div>
          <div id="cadreFS5_04" className="cadreFS5">
            <div className="eltFS_grue">
              <div className={CSSgrueFS04}>
                <div className="eltFS_grue_mvt0">
                  <img className="imgFondFS" src={PiafGrueJapMvtFond} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="fuji05" className={CSSFujiFS05}>
          <div id="txtFS_fs05" className={CSSTxtFS05}>
            <h2><span>Chaque fleur qui tombe</span></h2>
            <h2><span>Les fait vieillir davantage</span></h2>
            <h2><span>Les branches de prunier.</span></h2>
          </div>
          <div id="cadreFS5_05" className="cadreFS5" >
            <div className="eltFS_grue">
              <div className={CSSgrueFS05}>
                <div className="eltFS_grue_mvt0">
                  <img className="imgFondFS" src={PiafGrueJapMvtFond} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={CSSBtFS01} id="fuji01_b" onClick={ActFS_1}>
          <img className="imgFondFS" src={BoutonFS01_vide} />
        </div>
        <div className={CSSBtFS02} id="fuji02_b" onClick={ActFS_2}>
          <img className="imgFondFS" src={BoutonFS01_vide} />
        </div>
        <div className={CSSBtFS03} id="fuji03_b" onClick={ActFS_3}>
          <img className="imgFondFS" src={BoutonFS01_vide} />
        </div>
        <div className={CSSBtFS04} id="fuji04_b" onClick={ActFS_4}>
          <img className="imgFondFS" src={BoutonFS01_vide} />
        </div>
        <div className={CSSBtFS05} id="fuji05_b" onClick={ActFS_5}>
          <img className="imgFondFS" src={BoutonFS01_vide} />
        </div>
      </div>
  </>
}

export default ModuleFujiSan;