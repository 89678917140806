import React, { FunctionComponent, useState } from 'react';
import './module_exode_rural.css';
import FondVide from "../assets/img/img_space/FondVide800x720.gif"
import Fond00 from "../assets/img/img_space/ExodeRurale_Fond00.webp"
import Fond_BoutonCarre000 from "../assets/img/img_space/Fond_BoutonCarre000.gif"
import Fond_BoutonV000 from "../assets/img/img_space/Fond_BoutonV000.gif"

type Props = {
};

const ModuleExodeRural: FunctionComponent<Props> = () => {

  const [CSSERMvt01, setCSSERMvt01] = useState<string>("mvtER_01 eltER_vache01");
  const [CSSERMvt02, setCSSERMvt02] = useState<string>("mvtER_02 eltER_vache02");
  const [CSSERMvt03, setCSSERMvt03] = useState<string>("mvtER_03 eltER_cochon03");
  const [CSSERMvt04, setCSSERMvt04] = useState<string>("mvtER_04 eltER_gens04");

  const [tagERSansC, setTagERSansC] = useState<boolean>(false);
  const [tagERSansV, setTagERSansV] = useState<boolean>(false);
  const [tagERSansG, setTagERSansG] = useState<boolean>(false);
  const [tagERSansB, setTagERSansB] = useState<boolean>(false);

  const tagERSansCochon = ((e: React.FormEvent<HTMLFormElement>) => {
    if (tagERSansC == false) {
      setCSSERMvt03("mvtER_03 eltER_volaille03");
      setTagERSansC(true);
      setTagERSansB(false);
    }
    else {
      setCSSERMvt03("mvtER_03 eltER_cochon03");
      setTagERSansC(false);
      setTagERSansB(false);
    }
  })

  const tagERSansVache = ((e: React.FormEvent<HTMLFormElement>) => {
    if (tagERSansV == false) {
      setCSSERMvt01("mvtER_03 eltER_volaille01");
      setCSSERMvt02("mvtER_03 eltER_volaille02");
      setTagERSansV(true);
      setTagERSansB(false);
    }
    else {
      setCSSERMvt01("mvtER_01 eltER_vache01");
      setCSSERMvt02("mvtER_01 eltER_vache02");
      setTagERSansV(false);
      setTagERSansB(false);
    }
  })

  const tagERSansGens = ((e: React.FormEvent<HTMLFormElement>) => {
    if (tagERSansG == false) {
      setCSSERMvt04("mvtER_04 eltER_volaille04");
      setTagERSansG(true);
      setTagERSansB(false);
    }
    else {
      setCSSERMvt04("mvtER_04 eltER_gens04");
      setTagERSansG(false);
      setTagERSansB(false);
    }
  })

  const tagERSansBarbak = ((e: React.FormEvent<HTMLFormElement>) => {
    if (tagERSansB == false) {
      setCSSERMvt01("mvtER_03 eltER_vegan01");
      setCSSERMvt02("mvtER_03 eltER_vegan02");
      setCSSERMvt03("mvtER_03 eltER_vegan03");
      setCSSERMvt04("mvtER_03 eltER_vegan04");
      setTagERSansC(true);
      setTagERSansV(true);
      setTagERSansG(true);
      setTagERSansB(true);
    }
    else {
      setCSSERMvt01("mvtER_01 eltER_vache01");
      setCSSERMvt02("mvtER_01 eltER_vache02");
      setCSSERMvt03("mvtER_03 eltER_cochon03");
      setCSSERMvt04("mvtER_04 eltER_gens04");
      setTagERSansC(false);
      setTagERSansV(false);
      setTagERSansG(false);
      setTagERSansB(false);
    }
  })

  return <>
      <div className="cadreER" style={{ width: "100%" }}>
        <img className="imgFondER" src={FondVide} />
        <div className="cadreER3b">
          <img className="imgFondER" src={Fond00} />
          <div className="eltER_soucoupe01">
            <div className="eltER_soucoupe_luz01">
              <div className="eltER_rayon01">
              </div>
            </div>
          </div>
          <div className={CSSERMvt01}>
          </div>
          <div className={CSSERMvt02}>
          </div>
          <div className={CSSERMvt03}>
          </div>
          <div className={CSSERMvt04}>
          </div>
          {/*<div className="eltER_titre01">
          </div>*/}
        </div>
        <div className="cadreER3a">
          <div className="cadreER3aa" >
            <img className="imgFondER" src={Fond_BoutonV000} />
            <input type="checkbox" id="chkER_cochon01" checked={tagERSansC} onClick={e => tagERSansCochon(e)} />
            <label id="button2a" htmlFor="chkER_cochon01">
              <div id="knob_cochon01">
                <img className="imgFondER" src={Fond_BoutonCarre000} />
              </div>
            </label>
          </div>
          <div className="cadreER3aa">
            <img className="imgFondER" src={Fond_BoutonV000} />
            <input type="checkbox" id="chkER_vache01" checked={tagERSansV} onClick={e => tagERSansVache(e)} />
            <label id="button2b" htmlFor="chkER_vache01">
              <div id="knob_vache01">
                <img className="imgFondER" src={Fond_BoutonCarre000} />
              </div>
            </label>
          </div>
          <div className="cadreER3aa">
            <img className="imgFondER" src={Fond_BoutonV000} />
            <input type="checkbox" id="chkER_gens01" checked={tagERSansG} onClick={e => tagERSansGens(e)} />
            <label id="button2c" htmlFor="chkER_gens01">
              <div id="knob_gens01">
                <img className="imgFondER" src={Fond_BoutonCarre000} />
              </div>
            </label>
          </div>
          <div className="cadreER3aa">
            <img className="imgFondER" src={Fond_BoutonV000} />
            <input type="checkbox" id="chkER_vegan01" checked={tagERSansB} onClick={e => tagERSansBarbak(e)} />
            <label id="button2d" htmlFor="chkER_vegan01">
              <div id="knob_vegan01">
                <img className="imgFondER" src={Fond_BoutonCarre000} />
              </div>
            </label>
          </div>
        </div>
      </div>
  </>
}

export default ModuleExodeRural;
