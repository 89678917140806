import React, { FunctionComponent, useState } from 'react';
import Fond01 from "../assets/img/img_space/FondGrueJauneVid.gif"
import Carre000 from "../assets/img/img_space/Fond_BoutonCarre000.gif"
import './module_grue_jaune.css';

type Props = {
  };

const ModuleGrueJaune: FunctionComponent<Props> = () => {

    const [CCSGJGJtxt, setCCSGJGJtxt] = useState("none");

    const [CCSEnginGJ1, setCCSEnginGJ1] = useState<string>("sqGJ_00 sqGJ_01 sqGJ_hor dnoneGJ");
    const [CCSEnginGJ2, setCCSEnginGJ2] = useState<string>("sqGJ_00 sqGJ_02 sqGJ_hor dnoneGJ");
    const [CCSEnginGJ3, setCCSEnginGJ3] = useState<string>("sqGJ_00 sqGJ_03 sqGJ_hor dnoneGJ");
    const [CCSEnginGJ4, setCCSEnginGJ4] = useState<string>("sqGJ_00 sqGJ_04 sqGJ_hor dnoneGJ");
    const [CCSEnginGJ5, setCCSEnginGJ5] = useState<string>("sqGJ_00 sqGJ_05 sqGJ_ahor dnoneGJ");
    const [CCSEnginGJ6, setCCSEnginGJ6] = useState<string>("sqGJ_00 sqGJ_06 sqGJ_ahor dnoneGJ");
    const [CCSEnginGJ7, setCCSEnginGJ7] = useState<string>("sqGJ_00 sqGJ_07 sqGJ_ahor dnoneGJ");
    const [CCSEnginGJ8, setCCSEnginGJ8] = useState<string>("sqGJ_00 sqGJ_08 sqGJ_ahor dnoneGJ");

    const [CSSBtGJ1, setCSSBtGJ1] = useState<string>("mnuGJ_00 mnuGJ_01");
    const [CSSBtGJ2, setCSSBtGJ2] = useState<string>("mnuGJ_00 mnuGJ_02");
    const [CSSBtGJ3, setCSSBtGJ3] = useState<string>("mnuGJ_00 mnuGJ_03");
    const [CSSBtGJ4, setCSSBtGJ4] = useState<string>("mnuGJ_00 mnuGJ_04");
    const [CSSBtGJ5, setCSSBtGJ5] = useState<string>("mnuGJ_00 mnuGJ_05");
    const [CSSBtGJ6, setCSSBtGJ6] = useState<string>("mnuGJ_00 mnuGJ_06");
    const [CSSBtGJ7, setCSSBtGJ7] = useState<string>("mnuGJ_00 mnuGJ_07");
    const [CSSBtGJ8, setCSSBtGJ8] = useState<string>("mnuGJ_00 mnuGJ_08");

    const [CSSTagGJ1, setCSSTagGJ1] = useState<boolean>(false);
    const [CSSTagGJ2, setCSSTagGJ2] = useState<boolean>(false);
    const [CSSTagGJ3, setCSSTagGJ3] = useState<boolean>(false);
    const [CSSTagGJ4, setCSSTagGJ4] = useState<boolean>(false);
    const [CSSTagGJ5, setCSSTagGJ5] = useState<boolean>(false);
    const [CSSTagGJ6, setCSSTagGJ6] = useState<boolean>(false);
    const [CSSTagGJ7, setCSSTagGJ7] = useState<boolean>(false);
    const [CSSTagGJ8, setCSSTagGJ8] = useState<boolean>(false);

    const [zIndexGJ, setzIndexGJ1] = useState<number>(1000);
    const [CSSzIndexGJ1, setCSSzIndexGJ1] = useState<number>(zIndexGJ);
    const [CSSzIndexGJ2, setCSSzIndexGJ2] = useState<number>(zIndexGJ);
    const [CSSzIndexGJ3, setCSSzIndexGJ3] = useState<number>(zIndexGJ);
    const [CSSzIndexGJ4, setCSSzIndexGJ4] = useState<number>(zIndexGJ);
    const [CSSzIndexGJ5, setCSSzIndexGJ5] = useState<number>(zIndexGJ);
    const [CSSzIndexGJ6, setCSSzIndexGJ6] = useState<number>(zIndexGJ);
    const [CSSzIndexGJ7, setCSSzIndexGJ7] = useState<number>(zIndexGJ);
    const [CSSzIndexGJ8, setCSSzIndexGJ8] = useState<number>(zIndexGJ);

    const actGJEngin1 = () => {
        if (CSSTagGJ1) {
            setCCSEnginGJ1("sqGJ_00 sqGJ_01 sqGJ_ahor dnoneGJ");
            setCSSBtGJ1("mnuGJ_00 mnuGJ_01");
            setCSSTagGJ1(false)
        }
        else {
            setCCSEnginGJ1("sqGJ_00 sqGJ_01 sqGJ_ahor dnoneGJ activeGJ_hor");
            setCSSBtGJ1("mnuGJ_00 mnuGJ_01_active");
            setCSSTagGJ1(true);
            setzIndexGJ1(zIndexGJ + 5)
            setCSSzIndexGJ1(zIndexGJ)
        }
    }

    const actGJEngin2 = () => {
        if (CSSTagGJ2) {
            setCCSEnginGJ2("sqGJ_00 sqGJ_02 sqGJ_ahor dnoneGJ");
            setCSSBtGJ2("mnuGJ_00 mnuGJ_02");
            setCSSTagGJ2(false)
        }
        else {
            setCCSEnginGJ2("sqGJ_00 sqGJ_02 sqGJ_ahor dnoneGJ activeGJ_hor");
            setCSSBtGJ2("mnuGJ_00 mnuGJ_02_active");
            setCSSTagGJ2(true);
            setzIndexGJ1(zIndexGJ + 5)
            setCSSzIndexGJ2(zIndexGJ)
        }
    }

    const actGJEngin3 = () => {
        if (CSSTagGJ3) {
            setCCSEnginGJ3("sqGJ_00 sqGJ_03 sqGJ_ahor dnoneGJ");
            setCSSBtGJ3("mnuGJ_00 mnuGJ_03");
            setCSSTagGJ3(false)
        }
        else {
            setCCSEnginGJ3("sqGJ_00 sqGJ_03 sqGJ_ahor dnoneGJ activeGJ_hor");
            setCSSBtGJ3("mnuGJ_00 mnuGJ_03_active");
            setCSSTagGJ3(true);
            setzIndexGJ1(zIndexGJ + 5)
            setCSSzIndexGJ3(zIndexGJ)
        }
    }

    const actGJEngin4 = () => {
        if (CSSTagGJ4) {
            setCCSEnginGJ4("sqGJ_00 sqGJ_04 sqGJ_ahor dnoneGJ");
            setCSSBtGJ4("mnuGJ_00 mnuGJ_04");
            setCSSTagGJ4(false)
        }
        else {
            setCCSEnginGJ4("sqGJ_00 sqGJ_04 sqGJ_ahor dnoneGJ activeGJ_hor");
            setCSSBtGJ4("mnuGJ_00 mnuGJ_04_active");
            setCSSTagGJ4(true);
            setzIndexGJ1(zIndexGJ + 5)
            setCSSzIndexGJ4(zIndexGJ)
        }
    }

    const actGJEngin5 = () => {
        if (CSSTagGJ5) {
            setCCSEnginGJ5("sqGJ_00 sqGJ_05 sqGJ_ahor dnoneGJ");
            setCSSBtGJ5("mnuGJ_00 mnuGJ_05");
            setCSSTagGJ5(false)
        }
        else {
            setCCSEnginGJ5("sqGJ_00 sqGJ_05 sqGJ_ahor dnoneGJ activeGJ_ahor");
            setCSSBtGJ5("mnuGJ_00 mnuGJ_05_active");
            setCSSTagGJ5(true);
            setzIndexGJ1(zIndexGJ + 5)
            setCSSzIndexGJ5(zIndexGJ)
        }
    }

    const actGJEngin6 = () => {
        if (CSSTagGJ6) {
            setCCSEnginGJ6("sqGJ_00 sqGJ_06 sqGJ_ahor dnoneGJ");
            setCSSBtGJ6("mnuGJ_00 mnuGJ_06");
            setCSSTagGJ6(false)
        }
        else {
            setCCSEnginGJ6("sqGJ_00 sqGJ_06 sqGJ_ahor dnoneGJ activeGJ_ahor");
            setCSSBtGJ6("mnuGJ_00 mnuGJ_06_active");
            setCSSTagGJ6(true);
            setzIndexGJ1(zIndexGJ + 5)
            setCSSzIndexGJ6(zIndexGJ)
        }
    }

    const actGJEngin7 = () => {
        if (CSSTagGJ7) {
            setCCSEnginGJ7("sqGJ_00 sqGJ_07 sqGJ_ahor dnoneGJ");
            setCSSBtGJ7("mnuGJ_00 mnuGJ_07");
            setCSSTagGJ7(false)
        }
        else {
            setCCSEnginGJ7("sqGJ_00 sqGJ_07 sqGJ_ahor dnoneGJ activeGJ_ahor");
            setCSSBtGJ7("mnuGJ_00 mnuGJ_07_active");
            setCSSTagGJ7(true);
            setzIndexGJ1(zIndexGJ + 5)
            setCSSzIndexGJ7(zIndexGJ)
        }
    }

    const actGJEngin8 = () => {
        if (CSSTagGJ8) {
            setCCSEnginGJ8("sqGJ_00 sqGJ_08 sqGJ_ahor dnoneGJ");
            setCSSBtGJ8("mnuGJ_00 mnuGJ_08");
            setCSSTagGJ8(false)
        }
        else {
            setCCSEnginGJ8("sqGJ_00 sqGJ_08 sqGJ_ahor dnoneGJ activeGJ_ahor");
            setCSSBtGJ8("mnuGJ_00 mnuGJ_08_active");
            setCSSTagGJ8(true);
            setzIndexGJ1(zIndexGJ + 5)
            setCSSzIndexGJ8(zIndexGJ)
        }
    }

    return <>
            <div className="cadreGJ" style={{ width: "100%" }}>
                <div className="cadreGJ_pl"></div>
                <div className="cadre3232_bk01"></div>
                <div className="cadre3232_bk02"></div>
                <div className="cadre3232_bk03"></div>
                <img className="imgFondGJ" src={Fond01} />
                <div className={CCSEnginGJ1} style={{ zIndex: CSSzIndexGJ1 }}><img className="imgFondGJ" src={Carre000} /></div>
                <div className={CCSEnginGJ2} style={{ zIndex: CSSzIndexGJ2 }}><img className="imgFondGJ" src={Carre000} /></div>
                <div className={CCSEnginGJ3} style={{ zIndex: CSSzIndexGJ3 }}><img className="imgFondGJ" src={Carre000} /></div>
                <div className={CCSEnginGJ4} style={{ zIndex: CSSzIndexGJ4 }}><img className="imgFondGJ" src={Carre000} /></div>
                <div className={CCSEnginGJ5} style={{ zIndex: CSSzIndexGJ5 }}><img className="imgFondGJ" src={Carre000} /></div>
                <div className={CCSEnginGJ6} style={{ zIndex: CSSzIndexGJ6 }}><img className="imgFondGJ" src={Carre000} /></div>
                <div className={CCSEnginGJ7} style={{ zIndex: CSSzIndexGJ7 }}><img className="imgFondGJ" src={Carre000} /></div>
                <div className={CCSEnginGJ8} style={{ zIndex: CSSzIndexGJ8 }}><img className="imgFondGJ" src={Carre000} /></div>
                <div className="cadre3232_frt01" style={{ zIndex: zIndexGJ + 5 }}></div>
                <div className="cadre3232_frt02" style={{ zIndex: zIndexGJ + 5 }}></div>
                <div className="bull_txtGJ_00 bull_txtGJ_00R bull_txtGJ_09" style={{ display: CCSGJGJtxt }}>
                    <h3>Attention !</h3>
                </div>
                <div className="mnuGJ">
                    <div id="1" className={CSSBtGJ1} onClick={actGJEngin1}>
                        <img className="imgFondGJ" src={Carre000} />
                    </div>
                    <div id="2" className={CSSBtGJ2} onClick={actGJEngin2}>
                        <img className="imgFondGJ" src={Carre000} />
                    </div>
                    <div id="3" className={CSSBtGJ3} onClick={actGJEngin3}>
                        <img className="imgFondGJ" src={Carre000} />
                    </div>
                    <div id="4" className={CSSBtGJ4} onClick={actGJEngin4}>
                        <img className="imgFondGJ" src={Carre000} />
                    </div>
                    <div id="5" className={CSSBtGJ5} onClick={actGJEngin5}>
                        <img className="imgFondGJ" src={Carre000} />
                    </div>
                    <div id="6" className={CSSBtGJ6} onClick={actGJEngin6}>
                        <img className="imgFondGJ" src={Carre000} />
                    </div>
                    <div id="7" className={CSSBtGJ7} onClick={actGJEngin7}>
                        <img className="imgFondGJ" src={Carre000} />
                    </div>
                    <div id="8" className={CSSBtGJ8} onClick={actGJEngin8}>
                        <img className="imgFondGJ" src={Carre000} />
                    </div>
                </div>
            </div>
    </>
}

export default ModuleGrueJaune;
